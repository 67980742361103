import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "views/Components/Loader/Loader";
import { FormControl } from "react-bootstrap";
import Svg from "components/Svg/Svg.jsx";
import QuestionModal from "./QuestionModal.jsx";
import axios from "axios";
import { parseError } from "api/common.js";
import Swal from "sweetalert2";

const QuestionnairePage = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [questionModalVisible, setQuestionModalVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = () => {
    if (questions.length > 0) {
      return;
    }

    setLoading(true);
    setError(null);
    setQuestions([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/questionnaire`)
      .then((res) => {
        setQuestions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const deleteQuestion = (itemId) => {
    Swal.fire({
      title: "Delete Question",
      text: `Are you sure you want to delete this question? This action cannot be undone.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#189A62",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Question",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/questionnaire/${itemId}`)
          .then(() => {
            setQuestions((prev) => prev.filter((s) => s.id !== itemId));
            Swal.fire({
              title: `The question has been deleted successfully.`,
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            Swal.fire("Delete Error", error, "error");
          });
      }
    });
  };

  return (
    <div className="main-content flex flex-col gap-16 w-full" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center">
            <div className="title">Questionnaire</div>
            <Button
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
              onClick={() => {
                setSelectedQuestion(undefined);
                setQuestionModalVisible(true);
              }}
            >
              <Svg name="plus" className="w-4 h-4" />
              New Question
            </Button>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <div style={{ position: "relative" }} className="flex items-center gap-12">
              <Svg
                name="search"
                style={{
                  color: "var(--sub-color)",
                  position: "absolute",
                  left: "12px",
                  top: "10px",
                  width: "20px",
                  height: "20px",
                }}
              />
              <FormControl
                style={{ paddingLeft: "36px" }}
                disabled={loading}
                type="text"
                maxLength="100"
                name="reports-search-query"
                value={searchQuery}
                placeholder="Search questions..."
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />
            </div>

            {loading && (
              <div className="flex flex-col items-center justify-center">
                <Loader title="Loading questionnaire..." />
              </div>
            )}

            {error && (
              <div
                className="error-alert"
                style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
              >
                {error}
              </div>
            )}

            <div
              className="flex flex-col gap-6"
              style={{
                maxHeight: "calc(100vh - 250px)",
                minHeight: "360px",
                overflowY: "auto",
              }}
            >
              <div className="grid grid-2col gap-16" style={{ paddingBottom: "32px" }}>
                {questions
                  .filter(
                    (q) =>
                      !searchQuery ||
                      q.description.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
                  )
                  .map((q) => (
                    <div className="question-card" key={q.id}>
                      <div className="flex flex-col gap-12 w-full">
                        <div className="text-semibold text-normal">{q.description}</div>

                        <div className="flex flex-wrap gap-6">
                          {q.options.map((opt) => (
                            <div className="question-option" key={opt.id}>
                              {opt.description}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div
                        className="flex flex-col items-center gap-12 ml-auto"
                        style={{ alignSelf: "flex-start" }}
                      >
                        <Button
                          bsStyle="danger"
                          outline="true"
                          onClick={() => {
                            setSelectedQuestion(q);
                            setQuestionModalVisible(true);
                          }}
                          style={{ padding: "3px 4px" }}
                          title="View or Edit Question"
                        >
                          <Svg name="edit" className="w-4 h-4" />
                        </Button>
                        <Button
                          bsStyle="default"
                          outline="true"
                          className="btn-error"
                          title="Delete Question"
                          style={{ padding: "3px 4px" }}
                          onClick={() => {
                            deleteQuestion(q.id);
                          }}
                        >
                          <Svg name="trash" className="w-4 h-4" />
                        </Button>
                      </div>
                    </div>
                  ))}

                <QuestionModal
                  show={questionModalVisible}
                  question={selectedQuestion}
                  onHide={() => {
                    setQuestionModalVisible(false);
                    setSelectedQuestion(undefined);
                  }}
                  onConfirm={(question) => {
                    setQuestionModalVisible(false);
                    setSelectedQuestion(undefined);

                    if (questions.some((q) => q.id === question.id)) {
                      setQuestions((prev) =>
                        prev.map((q) => {
                          if (q.id === question.id) {
                            return question;
                          }
                          return q;
                        })
                      );
                      Swal.fire("Questionnaire", "All changes have been saved.", "success");
                    } else {
                      setQuestions((prev) => [question].concat(prev));
                      Swal.fire(
                        "Questionnaire",
                        "New questionnaire item has been added.",
                        "success"
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default QuestionnairePage;
