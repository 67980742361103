import axios from "axios"
import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import "bootstrap/dist/css/bootstrap.min.css"
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0"
import "assets/css/demo.css"
import "assets/css/pe-icon-7-stroke.css"
import "assets/css/main.css"
import "mapbox-gl/dist/mapbox-gl.css"

import AuthLayout from "layouts/Auth.jsx"
import MainLayout from "layouts/Main.jsx"
import LoginPage from "./views/Pages/LoginPage"
import Loader from "views/Components/Loader/Loader.jsx"
import { getSession, logout, setUser, setSession, getUserProfile } from "api/auth"

if (process.env.REACT_APP_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.8,
    environment: process.env.REACT_APP_ENV,
  })
}

function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        let session = getSession()
        if (session) {
          config.headers.Authorization = `Bearer ${session.accessToken}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    const createAxiosResponseInterceptor = () => {
      let session = getSession()
      let isAuthenticated = session !== null

      const interceptor = axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (!error.request || error.request.status !== 401 || !isAuthenticated) {
            return Promise.reject(error)
          }

          axios.interceptors.response.eject(interceptor)
          let refrshToken = session.refreshToken

          return axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/account/refreshtoken`, {
              refreshToken: refrshToken,
            })
            .then(function (res) {
              setSession(res.data)
              return axios(error.response.config)
            })
            .catch(function (err) {
              console.log(err)
              logout()
              window.location.href = "/"
            })
            .finally(() => {
              createAxiosResponseInterceptor()
            })
        }
      )
    }

    createAxiosResponseInterceptor()

    let isAuthenticated = getSession() !== null
    if (isAuthenticated) {
      getUserProfile()
        .then((res) => {
          setUser(res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          logout()
          window.location.href = "/"
        })
    } else {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [])

  let view
  if (loading) {
    view = (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Loader title="Loading Noosu Admin Panel..." />
      </div>
    )
  } else {
    view = (
      <BrowserRouter>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/admin" render={(props) => <MainLayout {...props} />} />
          <Route path="/auth/login" render={(props) => <LoginPage {...props} />} />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </BrowserRouter>
    )
  }

  return <React.Fragment>{view}</React.Fragment>
}

ReactDOM.render(<App />, document.getElementById("root"))
