import React, { useState, useEffect } from "react"
import moment from "moment"
import ReactDatetime from "react-datetime"
import Card from "components/Card/Card.jsx"
import Button from "components/CustomButton/CustomButton.jsx"
import axios from "axios"
import Svg from "components/Svg/Svg.jsx"
import Loader from "../../Components/Loader/Loader"
import { parseError, renderDatePickerInput } from "api/common.js"
import { dateFormat } from "api/common"

const CorporateStats = (props) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [fromDate, setFromDate] = useState(moment().startOf("month").toDate())
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate())

  useEffect(() => {
    onGetStatistics()
  }, [])

  const onGetStatistics = () => {
    setLoading(true)
    setData(null)
    setError(null)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/corporateclients/${encodeURIComponent(props.clientId)}/statistics?from=${
          fromDate ? moment(fromDate).startOf("day").toISOString() : ""
        }&to=${endDate ? moment(endDate).endOf("day").toISOString() : ""}`
      )
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  var isValidFromDate = (current) => {
    return endDate ? current.isSameOrBefore(endDate) : current.isBefore(moment())
  }

  var isValidEndDate = (current) => {
    return fromDate ? current.isSameOrAfter(fromDate) : current.isBefore(moment())
  }

  return (
    <div className="flex flex-col gap-16">
      <Card
        title="Appointment Statistics"
        content={
          <div className="flex flex-col gap-24">
            <div className="flex items-center gap-10">
              <ReactDatetime
                dateFormat={dateFormat}
                value={fromDate}
                closeOnSelect={true}
                renderInput={renderDatePickerInput}
                inputProps={{
                  disabled: loading,
                  placeholder: "From Date",
                }}
                timeFormat={false}
                isValidDate={isValidFromDate}
                onChange={(e) => setFromDate(e ? moment(e).toDate() : null)}
              />

              <ReactDatetime
                dateFormat={dateFormat}
                value={endDate}
                closeOnSelect={true}
                renderInput={renderDatePickerInput}
                inputProps={{
                  disabled: loading,
                  placeholder: "To Date",
                }}
                timeFormat={false}
                isValidDate={isValidEndDate}
                onChange={(e) => setEndDate(e ? moment(e).toDate() : null)}
              />

              <Button
                disabled={loading}
                loading={loading}
                style={{ height: "38px", marginLeft: "-6px" }}
                bsStyle="danger"
                fill
                onClick={() => onGetStatistics()}>
                <Svg name="search" className="w-4 h-4" />
                Get Statistics
              </Button>
            </div>

            <div className="separator horizontal"></div>

            <div className="flex flex-col gap-16">
              {loading && (
                <div className="flex flex-col items-center justify-center">
                  <Loader />
                  <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
                    Loading statistics...
                  </p>
                </div>
              )}

              {error && (
                <div className="error-alert" style={{ margin: "12px 0", padding: "0.6rem" }}>
                  {error}
                </div>
              )}

              {data && (
                <div className="flex w-full gap-24">
                  <div className="flex flex-col w-half gap-10">
                    <div className="text-xxs text-semibold main-text" style={{ marginBottom: "2px" }}>
                      APPOINTMENTS SUMMARY
                    </div>
                    <div className="flex items-center">
                      <span>
                        Pending <span className="sub-text text-xxs">(In Progress or Scheduled)</span>
                      </span>
                      <span className="text-bold ml-auto main-text">{data.appointments.pending}</span>
                    </div>
                    <div className="flex items-center">
                      <span>
                        Completed <span className="sub-text text-xxs">(Completed or Dropped Off)</span>
                      </span>
                      <span className="text-bold ml-auto success-text">{data.appointments.completed}</span>
                    </div>
                    <div className="flex items-center">
                      <span>Cancelled</span>
                      <span className="text-bold ml-auto theme-text">{data.appointments.cancelled}</span>
                    </div>
                    <div className="separator horizontal"></div>
                    <div className="flex items-center">
                      <span className="text-semibold">Total</span>
                      <span className="text-bold ml-auto">{data.appointments.total}</span>
                    </div>
                  </div>

                  <div className="separator vertical"></div>

                  <div className="flex flex-col items-start w-half">
                    <div className="text-xxs text-semibold main-text" style={{ marginBottom: "12px" }}>
                      COST BREAKDOWN (AGGREGATED)
                    </div>

                    <div className="flex flex-col w-full gap-10">
                      <React.Fragment>
                        <div className="flex items-center">
                          <span>Subtotal</span>
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "never",
                            }).format(data.subtotal > 0 ? data.subtotal / 100 : 0)}
                          </span>
                        </div>

                        <div className="flex items-center">
                          <span>GST</span>

                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "never",
                            }).format(data.gst > 0 ? data.gst / 100 : 0)}
                          </span>
                        </div>

                        {data.otherTax > 0 && (
                          <div className="flex items-center">
                            <span>Provincial Tax</span>
                            <span className="text-semibold ml-auto">
                              ${" "}
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                signDisplay: "never",
                              }).format(data.otherTax > 0 ? data.otherTax / 100 : 0)}
                            </span>
                          </div>
                        )}

                        <div className="flex items-center">
                          <span>Discount</span>
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "auto",
                            }).format(data.discount != 0 ? data.discount / 100 : 0)}
                          </span>
                        </div>

                        <div className="separator horizontal"></div>

                        <div className="flex items-center">
                          <span className="text-semibold">Total</span>
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "never",
                            }).format(data.total > 0 ? data.total / 100 : 0)}
                          </span>
                        </div>

                        <span style={{ marginTop: "12px" }} className="sub-text text-xxs">
                          The displayed amounts only include completed appointments (i.e. the appointment status is either 'Dropped Off' or 'Completed').
                        </span>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  )
}

export default CorporateStats
