import React, { Component } from "react"
import { Modal, Alert, Grid, Row, Col, FormControl, FormGroup, ControlLabel, Checkbox, InputGroup } from "react-bootstrap"
import axios from "axios"
import Card from "components/Card/Card.jsx"
import Swal from "sweetalert2"
import Button from "components/CustomButton/CustomButton.jsx"
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input"
import "react-phone-number-input/style.css"
import AutocompleteWithMap from "../../Components/AutocompleteWithMap/AutocompleteWithMap.jsx"
import { parseError } from "api/common.js"
import Svg from "components/Svg/Svg.jsx"

class AddCorporateClientModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      company: "",
      companyError: "",
      email: "",
      emailError: "",
      phone: "",
      phoneError: "",
      place: null,
      addressError: "",
      address: "",
      addressData: null,
      isBilledExternally: false,
      error: null,
      emailCounter: 1,
      addedAdmins: [],
    }
  }

  getAddress = (value) => {
    this.setState({ address: value })
  }

  setAddressData = (data) => {
    this.setState({ addressData: data })
  }

  resetState = () => {
    this.setState({
      loading: false,
      company: "",
      companyError: "",
      email: "",
      emailError: "",
      phone: "",
      phoneError: "",
      place: null,
      addressError: "",
      address: "",
      addressData: null,
      isBilledExternally: false,
      error: null,
      addedAdmins: [],
      emailCounter: 1,
    })
  }

  handleEmailOnBlur(event) {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    re.test(event.target.value) === false
      ? this.setState({
          emailError: (
            <small className="text-danger">
              Email is required and format should be <i>john@doe.com</i>.
            </small>
          ),
        })
      : this.setState({ emailError: null })
  }

  handleEmailChange(value, index) {
    if (index === 0) {
      this.setState({ email: value })
    } else {
      let temp = [...this.state.addedAdmins]
      temp[index - 1] = value
      this.setState({ addedAdmins: temp })
    }
  }

  handleAddEmailAddress = (e) => {
    e.preventDefault()
    this.setState({
      emailCounter: this.state.emailCounter + 1,
      addedAdmins: [...this.state.addedAdmins, ""],
    })
  }
  handleRemoveEmailAddress = (e, index) => {
    e.preventDefault()
    // remove the indexed added admin email
    this.setState({ emailCounter: this.state.emailCounter - 1 })
    // index needs to -1 to remove the corresponding value
    let temp = [...this.state.addedAdmins]
    temp.splice(index - 1, 1)
    this.setState({ addedAdmins: temp })
  }

  submitCorporateUser = () => {
    const self = this
    const { addressData, company, phone, notes, isBilledExternally, email, addedAdmins } = this.state

    let object = {
      name: company,
      phone: phone,
      address: {
        province: addressData.province,
        city: addressData.city,
        street: addressData.street,
        postalCode: addressData.postalCode,
      },
      latitude: addressData.latitude,
      longitude: addressData.longitude,
      isBilledExternally: isBilledExternally,
      admin: {
        email: email,
      },
      addedAdmins: addedAdmins,
      notes: notes,
    }

    this.setState({ loading: true, error: null })

    axios
      .post(`${process.env.REACT_APP_API_URL}/corporateclients`, object)
      .then((res) => {
        Swal.fire(
          "Corporate Client",
          "Corporate account created successfully, an email has been sent to the specified email address to complete registration",
          "success"
        )
        self.setState({ loading: false })
        self.props.onSubmit()
        self.resetState()
        self.props.onHide()
      })
      .catch((err) => {
        let error = parseError(err)
        self.setState({ loading: false, error: error })
      })
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.props.onHide()
          this.resetState()
        }}>
        <Modal.Header closeButton>
          <Modal.Title>New Corporate Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card
            content={
              <div className="flex flex-col gap-12">
                <div className="grid grid-2col gap-24">
                  <FormGroup style={{ marginBottom: "0" }}>
                    <ControlLabel>
                      Company Name: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      type="text"
                      name="company"
                      disabled={this.state.loading}
                      onChange={(event) => {
                        this.setState({ company: event.target.value })
                      }}
                      onBlur={(event) => {
                        event.target.value === ""
                          ? this.setState({
                              companyError: <small className="text-danger">Company name is required.</small>,
                            })
                          : this.setState({ companyError: null })
                      }}
                    />
                    {this.state.companyError}
                  </FormGroup>

                  <FormGroup style={{ marginBottom: "0" }}>
                    <ControlLabel>
                      Email address: <span className="star">*</span>
                    </ControlLabel>
                    {Array.from(Array(this.state.emailCounter)).map((c, index) => {
                      return (
                        <div className="flex" key={index} style={{ marginBottom: "4px" }}>
                          <FormControl
                            disabled={this.state.loading}
                            type="text"
                            name="email"
                            onChange={(event) => this.handleEmailChange(event.target.value, index)}
                            onBlur={(event) => this.handleEmailOnBlur(event)}
                            required
                            value={index > 0 ? this.state.addedAdmins[index - 1] : this.state.email}
                            className=""
                            placeholder={index > 0 ? "Additional Email" : ""}
                          />
                          {index === this.state.emailCounter - 1 && (
                            <Button
                              disabled={this.state.emailError !== null}
                              variant="outline-secondary"
                              id="button-addon2"
                              onClick={(e) => this.handleAddEmailAddress(e)}>
                              +
                            </Button>
                          )}
                          {this.state.emailCounter > 1 && index !== 0 && (
                            <Button variant="outline-secondary" id="button-addon2" onClick={(e) => this.handleRemoveEmailAddress(e, index)}>
                              -
                            </Button>
                          )}
                        </div>
                      )
                    })}

                    {this.state.emailError}
                  </FormGroup>

                  <div className="form-group phone-input" style={{ marginBottom: "0" }}>
                    <ControlLabel>
                      Phone Number: <span className="star">*</span>
                    </ControlLabel>
                    <PhoneInput
                      maxLength="16"
                      max="10"
                      defaultCountry="CA"
                      extension="true"
                      disabled={this.state.loading}
                      value={this.state.phone}
                      onChange={(value) => {
                        this.setState({ phone: value })
                        if (value && !isPossiblePhoneNumber(value)) {
                          this.setState({
                            phoneError: <small className="text-danger">A valid Canadian or US phone number is required.</small>,
                          })
                        } else {
                          this.setState({
                            phoneError: null,
                          })
                        }
                      }}
                      required
                    />
                    {this.state.phoneError}
                  </div>

                  <FormGroup style={{ marginBottom: "0" }}>
                    <ControlLabel>Notes:</ControlLabel>
                    <FormControl
                      disabled={this.state.loading}
                      type="textarea"
                      name="notes"
                      onChange={(event) => {
                        this.setState({ notes: event.target.value })
                      }}
                    />
                  </FormGroup>

                  <div className="flex item-center" style={{ padding: "6px 0px", marginBottom: "12px" }}>
                    <Checkbox
                      onChange={() => {
                        this.setState({
                          isBilledExternally: !this.state.isBilledExternally,
                        })
                      }}
                      inline>
                      External Billing (bypass Stripe)
                    </Checkbox>
                  </div>
                </div>

                <AutocompleteWithMap height="300px" address={this.state.address} onSelectAddress={this.getAddress} setAddressData={this.setAddressData} />

                {this.state.address && <h6>{this.state.address.replace("undefined ", "")}</h6>}

                {this.state.error && (
                  <div className="error-alert" style={{ padding: "0.6rem", margin: "12px 0" }}>
                    {this.state.error}
                  </div>
                )}

                <div className="flex items-center">
                  <Button
                    outline="true"
                    bsStyle="danger"
                    disabled={this.state.loading}
                    onClick={() => {
                      this.props.onHide()
                      this.resetState()
                    }}
                    style={{ marginLeft: "auto" }}>
                    Cancel
                  </Button>

                  <Button
                    loading={this.state.loading}
                    disabled={
                      this.state.loading ||
                      !this.state.email ||
                      this.state.emailError !== null ||
                      !this.state.company ||
                      this.state.companyError !== null ||
                      this.state.phoneError != null ||
                      !this.state.phone ||
                      !this.state.addressData
                    }
                    type="submit"
                    onClick={() => this.submitCorporateUser()}
                    bsStyle="danger"
                    fill
                    style={{ marginLeft: "12px" }}>
                    Create Account
                  </Button>
                </div>
              </div>
            }
          />
        </Modal.Body>
      </Modal>
    )
  }
}

export default AddCorporateClientModal
