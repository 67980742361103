import React, { useState, useEffect } from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Grid,
  Row,
  Col,
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import DatesListControl from "../DropOffLocations/DatesListControl/DatesListControl.jsx";
import OpenCloseTime from "../DropOffLocations/OpenCloseTime.jsx";
import LocalizableTextInput from "../../Components/LocalizableInputs/LocalizableTextInput.jsx";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Loader from "../../Components/Loader/Loader.jsx";
import { parseError } from "api/common.js";
import TimePicker from "react-time-picker";
import { formatFullAddress } from "api/common.js";
import Svg from "components/Svg/Svg.jsx";
import AddLocationModal from "../../Components/AutocompleteWithMap/AddLocationModal.jsx";
import BannerPanel from "views/Components/BannerPanel/BannerPanel.jsx";

const ServiceCentreView = (props) => {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState(null);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [localizations, setLocalizations] = useState({});
  const [centreId, setCentreId] = useState(null);
  const [centreData, setCentreData] = useState(null);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [hoursOfOperations, setHoursOfOperation] = useState([
    {
      dayOfWeek: "Sunday",
      start: { value: "Closed", label: "Closed" },
      end: { value: "Closed", label: "Closed" },
    },
    {
      dayOfWeek: "Monday",
      start: { value: "Closed", label: "Closed" },
      end: { value: "Closed", label: "Closed" },
    },
    {
      dayOfWeek: "Tuesday",
      start: { value: "Closed", label: "Closed" },
      end: { value: "Closed", label: "Closed" },
    },
    {
      dayOfWeek: "Wednesday",
      start: { value: "Closed", label: "Closed" },
      end: { value: "Closed", label: "Closed" },
    },
    {
      dayOfWeek: "Thursday",
      start: { value: "Closed", label: "Closed" },
      end: { value: "Closed", label: "Closed" },
    },
    {
      dayOfWeek: "Friday",
      start: { value: "Closed", label: "Closed" },
      end: { value: "Closed", label: "Closed" },
    },
    {
      dayOfWeek: "Saturday",
      start: { value: "Closed", label: "Closed" },
      end: { value: "Closed", label: "Closed" },
    },
  ]);
  const [closureDates, setClosureDates] = useState([]);
  const [asapInterval, setAsapInterval] = useState("00:30:00");
  const [scheduledInterval, setScheduledInterval] = useState("00:30:00");
  const [banner, setBanner] = useState(undefined);

  useEffect(() => {
    loadServiceCentre();
  }, []);

  const loadServiceCentre = () => {
    setLoading(true);
    setError(null);
    setCentreData(null);
    let centreId = props.match.params.id;
    setCentreId(centreId);

    if (centreId !== "new") {
      axios
        .get(`${process.env.REACT_APP_API_URL}/service-centres/${centreId}`)
        .then((scRes) => {
          setLoading(false);
          let centre = scRes.data;
          prepareData(centre);
        })
        .catch((scErr) => {
          let error = parseError(scErr);
          setLoading(false);
          setError(error);
        });
    } else {
      setLoading(false);
    }
  };

  const onChangeHoursOfOperation = (data) => {
    setHoursOfOperation((list) =>
      list.map((h) => {
        if (h.dayOfWeek === data.dayOfWeek) {
          return {
            dayOfWeek: h.dayOfWeek,
            start: data.startTime,
            end: data.endTime,
          };
        }
        return h;
      })
    );
  };

  const prepareData = (centre) => {
    setBanner(centre.banner && centre.banner);
    let hopList = hoursOfOperations.map((hop) => {
      var scItem = centre.hoursOfOperations.find(
        (hh) => hh.dayOfWeek === hop.dayOfWeek
      );
      if (scItem) {
        let start = moment(
          scItem.startTime.hour + ":" + scItem.startTime.minute,
          ["h:mm A"]
        );
        let end = moment(scItem.endTime.hour + ":" + scItem.endTime.minute, [
          "h:mm A",
        ]);

        return {
          dayOfWeek: hop.dayOfWeek,
          start: {
            value: start.format("h:mm A"),
            label: start.format("h:mm A"),
          },
          end: { value: end.format("h:mm A"), label: end.format("h:mm A") },
        };
      }

      return {
        dayOfWeek: hop.dayOfWeek,
        start: { value: "Closed", label: "Closed" },
        end: { value: "Closed", label: "Closed" },
      };
    });

    setHoursOfOperation(hopList);
    setCentreData(centre);
    setName(centre.name);
    setLocalizations(centre.localizations || {});
    setClosureDates(
      centre.closureDates &&
        centre.closureDates.map((item, ind) => {
          return {
            id: `${ind}-${Math.floor(Math.random() * 999999)}`,
            date: moment(
              `${item.date.year}-${item.date.month
                .toString()
                .padStart(2, "0")}-${item.date.day
                .toString()
                .padStart(2, "0")}`,
              "YYYY-MM-DD"
            ).toDate(),
            text: item.reason,
          };
        })
    );
    setAsapInterval(centre.asapIntervalBeforeClosure);

    setScheduledInterval(centre.scheduledIntervalBeforeClosure);

    let addr = Object.assign({}, centre.address, centre.location);
    setAddress(addr);
    console.log(addr);
  };

  const onSaveChanges = () => {
    setError(null);
    setSaveLoading(true);

    const obj = {
      id: centreId === "new" ? null : centreId,
      name: name,
      localizations: localizations || {},
      address: {
        province: address.region || address.province,
        city: address.city || address.place,
        street: address.street,
        postalCode: address.postalCode || address.postCode,
        unitNumber: address.unitNumber,
      },
      location: {
        latitude: address.latitude,
        longitude: address.longitude,
      },
      banner: banner ? banner : null,
      asapIntervalBeforeClosure: asapInterval,
      scheduledIntervalBeforeClosure: scheduledInterval,
      hoursOfOperations: hoursOfOperations
        .filter((s) => s.start.value !== "Closed" && s.end.value !== "Closed")
        .map((item) => {
          let start = moment(item.start.value, ["h:mm A"]);
          let end = moment(item.end.value, ["h:mm A"]);

          return {
            dayOfWeek: item.dayOfWeek,
            startTime: {
              hour: parseInt(start.format("H")),
              minute: parseInt(start.format("m")),
            },
            endTime: {
              hour: parseInt(end.format("H")),
              minute: parseInt(end.format("m")),
            },
          };
        }),
      closureDates: closureDates
        .filter((cd) => cd.date && cd.text)
        .map((cDate) => {
          return {
            date: {
              year: cDate.date.getFullYear(),
              month: cDate.date.getMonth() + 1,
              day: cDate.date.getDate(),
            },
            reason: cDate.text,
          };
        }),
    };

    if (centreId === "new") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/service-centres`, obj)
        .then((res) => {
          setSaveLoading(false);
          Swal.fire(
            "Created Successfully",
            `Service Centre '${obj.name}' has been successfully created.`,
            "success"
          );

          props.history.push("/admin/service-centres");
        })
        .catch((err) => {
          setSaveLoading(false);
          let error = parseError(err);
          Swal.fire("Save Error", error, "error");
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_API_URL}/service-centres/${obj.id}`, obj)
        .then((res) => {
          setSaveLoading(false);
          let centre = res.data;
          Swal.fire(
            "Saved Successfully",
            "All changes have been successfully saved.",
            "success"
          );
          prepareData(centre);
        })
        .catch((err) => {
          setSaveLoading(false);
          let error = parseError(err);
          Swal.fire("Save Error", error, "error");
        });
    }
  };

  console.log("address", address);

  return (
    <div className="main-content flex flex-col gap-16">
      <div className="flex items-start">
        <Button
          simple
          icon
          style={{ padding: "0", marginRight: "auto" }}
          onClick={() => {
            props.history.go(-1);
          }}>
          <Svg name="chevron-left" className="w-6 h-6" />
          <span className="text-bold">Go Back</span>
        </Button>

        <Button
          disabled={
            loading ||
            saveLoading ||
            !name ||
            !address ||
            !hoursOfOperations.every
          }
          loading={saveLoading}
          bsStyle="danger"
          fill
          style={{ marginLeft: "auto" }}
          onClick={() => onSaveChanges()}>
          Save Changes
        </Button>
      </div>

      {loading && (
        <div
          className="flex flex-col items-center justify-center"
          style={{ marginBottom: "32px" }}>
          <Loader title="Loading Service Centre Details..." />
        </div>
      )}

      {error && (
        <div
          className="error-alert"
          style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}>
          {error}
        </div>
      )}

      <div className="grid grid-2col gap-16">
        <Card
          className="col-2span"
          title={name}
          content={
            <div className="grid grid-2col gap-24">
              <FormGroup style={{ marginBottom: "0" }}>
                <ControlLabel>
                  Service Centre Name: <span className="star">*</span>
                </ControlLabel>
                <LocalizableTextInput
                  disabled={loading}
                  placeholder="Enter name for this location"
                  value={name}
                  maxLength={100}
                  onChange={(text) => {
                    setName(text);
                  }}
                  onLocalizationChange={(translation) => {
                    setLocalizations((prev) => {
                      let newObj = Object.assign({}, prev);
                      Object.assign(newObj, translation);
                      return newObj;
                    });
                  }}
                  fieldName="Name"
                  localizations={localizations}
                />
              </FormGroup>

              <div className="flex flex-col gap-12 items-start">
                <div
                  className="form-group"
                  style={{ paddingLeft: 0, marginBottom: 0 }}>
                  <ControlLabel>
                    Address: <span className="star">*</span>
                  </ControlLabel>

                  <div className="text-lg main-text">
                    {address.street && address.street}
                    {", "}
                    {address.city && address.city}{" "}
                    {address.province && address.province}{" "}
                    {address.postalCode && address.postalCode}
                  </div>

                  {!address && (
                    <div className="sub-text">No address provided</div>
                  )}
                </div>

                <Button
                  disabled={loading}
                  bsStyle="danger"
                  style={{ marginRight: "auto" }}
                  fill
                  onClick={() => setLocationModalOpen(true)}>
                  <Svg name="pin" className="w-4 h-4" />
                  {address ? "Edit Address" : "Select Address"}
                </Button>
              </div>
            </div>
          }
        />

        <Card
          className="col-2span"
          title="Marketing Banner"
          content={
            <BannerPanel
              banner={banner}
              onChange={(uBanner) => {
                setBanner(uBanner);
              }}
            />
          }
        />

        <Card
          title="Hours of operation"
          content={
            <div>
              {hoursOfOperations.map((item, key) => (
                <OpenCloseTime
                  isDisabled={loading}
                  key={key}
                  changeWeekTime={(data) => onChangeHoursOfOperation(data)}
                  Day={item.dayOfWeek}
                  Open={item.start}
                  Close={item.end}
                />
              ))}
            </div>
          }
        />

        <div className="flex flex-col gap-24">
          <Card
            title="Closure Dates"
            content={
              <div>
                <DatesListControl
                  disabled={loading}
                  items={closureDates}
                  onAdd={(item) => {
                    setClosureDates((prev) => [...prev, item]);
                  }}
                  onChange={(item) => {
                    setClosureDates((prev) =>
                      prev.map((i) => {
                        if (i.id === item.id) {
                          return Object.assign({}, item);
                        }
                        return i;
                      })
                    );
                  }}
                  onDelete={(item) => {
                    setClosureDates((prev) =>
                      prev.filter((s) => s.id !== item.id)
                    );
                  }}
                  textLabel="REASON"
                />
              </div>
            }
          />

          <Card
            title="Interval Before Closure"
            content={
              <div className="closure-interval">
                <FormGroup>
                  <ControlLabel>
                    ASAP interval before closure:{" "}
                    <span className="star">*</span>
                  </ControlLabel>
                  <TimePicker
                    disableClock
                    clearIcon=""
                    value={asapInterval}
                    hourPlaceholder="Hour"
                    minutePlaceholder="Minute"
                    locale="ca-CA"
                    maxDetail="second"
                    onChange={(asapInterval) => setAsapInterval(asapInterval)}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    Scheduled interval before closure:{" "}
                    <span className="star">*</span>
                  </ControlLabel>
                  <TimePicker
                    style={{ outerWidth: "100%" }}
                    disableClock
                    clearIcon=""
                    value={scheduledInterval}
                    hourPlaceholder="Hour"
                    minutePlaceholder="Minute"
                    locale="ca-CA"
                    maxDetail="second"
                    onChange={(scheduledInterval) =>
                      setScheduledInterval(scheduledInterval)
                    }
                  />
                </FormGroup>
              </div>
            }
          />

          <AddLocationModal
            show={locationModalOpen}
            onHide={() => setLocationModalOpen(false)}
            address={address && address}
            onSelectAddress={setAddress}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceCentreView;
