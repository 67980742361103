import React, { Component } from "react"
import { Navbar } from "react-bootstrap"
import AdminNavbarLinks from "./AdminNavbarLinks.jsx"
import routes from "routes.js"
import { useWindowSize } from "api/common.js"

const AdminNavbar = (props) => {
  const { width: windowWidth } = useWindowSize()

  const getActiveRoute = (routes) => {
    let activeRoute = "Avvy"
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }

  const mobileSidebarToggle = (e) => {
    document.documentElement.classList.toggle("nav-open")
  }

  return (
    <nav className={`${props.navbar ? "navbar-fixed" : ""} navbar navbar-default flex w-full items-center`} style={{ padding: "0" }}>
      <div className="navbar-minimize">
        <button id="minimizeSidebar" className="btn btn-default btn-fill btn-round btn-icon" onClick={props.handleMiniClick}>
          <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
          <i className="fa fa-navicon visible-on-sidebar-mini" />
        </button>
      </div>
      <Navbar.Header>
        <Navbar.Brand>{getActiveRoute(routes)}</Navbar.Brand>
        <Navbar.Toggle onClick={mobileSidebarToggle} />
      </Navbar.Header>
      {windowWidth > 992 ? (
        <Navbar.Collapse style={{ padding: "0" }} className="flex items-center">
          <AdminNavbarLinks />
        </Navbar.Collapse>
      ) : null}
    </nav>
  )
}

export default AdminNavbar
