import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import moment from "moment";
import Svg from "../../../components/Svg/Svg";
import Swal from "sweetalert2";
import { parseError } from "api/common.js";
import Select from "react-select";
import AddLocationModal from "../../Components/AutocompleteWithMap/AddLocationModal.jsx";
import PatientAppointments from "../Appointments/AppointmentsTable.jsx";
import ReactDatetime from "react-datetime";
import Loader from "../../../views/Components/Loader/Loader.jsx";
import HealthCardPreviewModal from "../Appointments/HealthCardPreviewModal";
import UserReviews from "./UserReviews.jsx";
import Rating from "react-rating";
import { dateFormat, dateTimeFormat } from "api/common";
import { formatFullAddress } from "api/common";

const UserDetails = (props) => {
  const healthCardFileInput = useRef();
  const [patient, setPatient] = useState(null);
  const [patientId, setPatientId] = useState("");
  const [adminNotes, setAdminNotes] = useState("");
  const [gender, setGender] = useState({ value: "" });
  const [genders, setGenders] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [address, setAddress] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [hcExpiryDate, setHcExpiryDate] = useState(null);
  const [hcNumber, setHcNumber] = useState("");
  const [hcProvince, setHcProvince] = useState({ value: "" });
  const [provinces, setProvinces] = useState([]);
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [allergies, setAllergies] = useState("");
  const [healthCardFile, setHealthCardFile] = useState(null);
  const [healthCardFileId, setHealthCardFileId] = useState(null);
  const [healthCardKey, setHealthCardKey] = useState(null);
  const [notesEditMode, setNotesEditMode] = useState(false);

  useEffect(() => {
    let id = props.match.params.id;

    if (id) {
      setPatientId(id);
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}/account/${id}`)
        .then((res) => {
          setLoading(false);
          const patientData = res.data;
          initializePatientData(patientData);

          // axios
          //   .get(`${process.env.REACT_APP_API_URL}/account/genders`)
          //   .then((genRes) => {
          //     var genders = genRes.data.map((s) => {
          //       return { label: s.value, value: s.key };
          //     });

          //     setGenders(genders);
          //   })
          //   .catch((err) => {
          //     parseError(err);
          //   });

          axios
            .get(`${process.env.REACT_APP_API_URL}/platform/provinces`)
            .then((provRes) => {
              let provinceList = provRes.data.map((p) => {
                return {
                  label: p.value,
                  value: p.key,
                };
              });
              setProvinces(provinceList);

              if (patientData.healthCareCard && patientData.healthCareCard.province) {
                let prv = provinceList.find((s) => s.value === patientData.healthCareCard.province);
                if (prv) {
                  setHcProvince(prv);
                }
              }
            })
            .catch((err) => {
              parseError(err);
            });
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          Swal.fire("Account Error", error, "error");
        });
    }
  }, []);

  const initializePatientData = (patientData) => {
    setPatient(patientData);
    setAdminNotes(patientData.adminNotes);
    setFirstName(patientData.firstName);
    setLastName(patientData.lastName);
    // setGender(
    //   genders.length > 0
    //     ? genders.find((g) => g.value === patientData.gender.key)
    //     : { value: patientData.gender.key, label: patientData.gender.value }
    // );
    setDateOfBirth(
      moment(
        `${patientData.dateOfBirth.month}/${patientData.dateOfBirth.day}/${patientData.dateOfBirth.year}`,
        "M/D/YYYY"
      ).toDate()
    );

    let addressData = Object.assign({}, patientData.address, patientData.location);
    setAddress(addressData);

    if (patientData.healthCareCard) {
      setHcNumber(patientData.healthCareCard.cardNumber || "");
      setHcProvince(
        provinces.length > 0
          ? provinces.find((p) => p.value === patientData.healthCareCard.province)
          : { value: patientData.healthCareCard.province }
      );
      if (patientData.healthCareCard.expiryDate) {
        setHcExpiryDate(
          moment(
            `${patientData.healthCareCard.expiryDate.day}/${patientData.healthCareCard.expiryDate.month}/${patientData.healthCareCard.expiryDate.year}`,
            "D/M/YYYY"
          ).toDate()
        );
      }
      setHealthCardFileId(
        patientData.healthCareCard.picture ? patientData.healthCareCard.picture.fileName : null
      );
    } else {
      setHcNumber("");
      setHcProvince(null);
      setHcExpiryDate(null);
      setHealthCardFileId(null);
      setHealthCardFile(null);
      setHealthCardKey(null);
    }

    if (patientData.insurance) {
      setInsuranceNumber(patientData.insurance.policyNumber || "");
      setInsuranceProvider(patientData.insurance.provider || "");
    }

    setAllergies(patientData.issuesAllergies || "");
  };

  const updateUserInfo = async () => {
    setLoading(true);

    const patientData = {
      id: patientId,
      firstName: firstName,
      lastName: lastName,
      phone: patient.phone,
      address: {
        province: address.province,
        city: address.city,
        street: address.street,
        postalCode: address.postalCode,
        unitNumber: address.unitNumber,
      },
      // gender: {
      //   key: gender.value,
      //   value: gender.label,
      // },
      dateOfBirth: {
        year: dateOfBirth.getFullYear(),
        month: dateOfBirth.getMonth() + 1,
        day: dateOfBirth.getDate(),
      },
      location: {
        latitude: address.latitude,
        longitude: address.longitude,
      },
      insurance: {
        provider: insuranceProvider,
        policyNumber: insuranceNumber,
      },
      issuesAllergies: allergies,
    };

    let uploadCompleted = false;

    if (hcNumber && hcNumber.length > 0 && hcProvince && (healthCardFileId || healthCardFile)) {
      patientData.healthCareCard = {
        cardNumber: hcNumber,
        province: hcProvince.value,
        picture: {
          fileName: healthCardFileId,
        },
        expiryDate: hcExpiryDate
          ? {
              year: hcExpiryDate.getFullYear(),
              month: hcExpiryDate.getMonth() + 1,
              day: hcExpiryDate.getDate(),
            }
          : null,
      };

      try {
        if (healthCardFile) {
          const file = healthCardFile;
          let formData = new FormData();
          formData.append("file", file, file.name);

          let fileRes = await axios.post(
            `${process.env.REACT_APP_API_URL}/file/upload/healthcard`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          patientData.healthCareCard.picture = {
            fileName: fileRes.data,
          };
        }
        uploadCompleted = true;
      } catch (err) {
        let error = parseError(err);
        Swal.fire("Update Error", error, "error");
        setLoading(false);
      }

      if (!uploadCompleted) {
        Swal.fire(
          "File Upload Error",
          "Unable to upload health card picture due to unexpected error.",
          "error"
        );
        return;
      }
    }

    axios
      .put(`${process.env.REACT_APP_API_URL}/Account`, patientData)
      .then((res) => {
        setLoading(false);
        setEditMode(false);
        initializePatientData(res.data);
        Swal.fire("User Update", "User information has been updated successfully.", "success");
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Update Error", error, "error");
      });
  };

  const deleteAccount = async () => {
    Swal.fire({
      title: "Are you sure you want to delete this account?",
      text: "You can only delete the customer's account if they do not have any not completed appointments booked for them.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#189A62",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Account",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        axios
          .delete(`${process.env.REACT_APP_API_URL}/account/${patientId}`)
          .then(() => {
            setPatient((prev) =>
              Object.assign({}, prev, { deletedAt: Math.round(Date.now() / 1000) })
            );
            Swal.fire({
              title: "Account has been deleted.",
              icon: "success",
              showCancelButton: false,
            }).then(() => {
              props.history.push("/admin/users");
            });
          })
          .catch((err) => {
            let error = parseError(err);
            Swal.fire("Account Delete Error", error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const disableAccount = async () => {
    Swal.fire({
      title: "Are you sure you want to disable this account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#189A62",
      cancelButtonColor: "#565656",
      confirmButtonText: "Disable Account",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        axios
          .delete(`${process.env.REACT_APP_API_URL}/Account/${patientId}/disable`)
          .then((res) => {
            setLoading(false);
            let updatedPatient = Object.assign({}, patient);
            updatedPatient.status = "disabled";
            setPatient(updatedPatient);

            Swal.fire({
              title: "Account has been disabled.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            setLoading(false);
            Swal.fire("Update Error", error, "error");
          });
      }
    });
  };

  const enableAccount = async () => {
    Swal.fire({
      title: "Are you sure you want to enable this account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#189A62",
      cancelButtonColor: "#565656",
      confirmButtonText: "Enable Account",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        axios
          .put(`${process.env.REACT_APP_API_URL}/Account/${patientId}/enable`)
          .then((res) => {
            setLoading(false);
            let updatedPatient = Object.assign({}, patient);
            updatedPatient.status = "enabled";
            setPatient(updatedPatient);

            Swal.fire({
              title: "Account has been enabled.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            setLoading(false);
            Swal.fire("Update Error", error, "error");
          });
      }
    });
  };

  const onAddressSelected = (value) => {
    setAddress(value);
  };

  const changeDate = (event) => {
    setDateOfBirth(event.toDate());
  };

  const disableFutureDt = (current) => {
    return current.isBefore(moment()) && current.isAfter(moment("1-1-1800", "D-M-YYYY"));
  };

  const isValidExpiryDate = function (current) {
    return current.isAfter(moment());
  };

  const onHealthCardFileUpload = () => {
    healthCardFileInput.current.click();
  };

  const onHealthCardFileChange = (e) => {
    setHealthCardFile(e.target.files[0]);
    setHealthCardFileId(null);
  };

  const cancelChanges = () => {
    setEditMode(false);
    if (patient) {
      initializePatientData(patient);
    }
  };

  const onSaveNotes = () => {
    setLoading(true);

    axios
      .put(`${process.env.REACT_APP_API_URL}/account/${patientId}/notes`, { notes: adminNotes })
      .then((res) => {
        setLoading(false);
        setNotesEditMode(false);
        setPatient((prev) =>
          Object.assign(Object.assign({}, prev), { adminNotes: res.data.adminNotes })
        );

        Swal.fire({
          title: "The customer's admin notes have been updated succesfully.",
          icon: "success",
          showCancelButton: false,
        });
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
      });
  };

  const getEpochDate = (epoch) => {
    var date = new Date(0);
    date.setUTCSeconds(epoch);
    return date;
  };

  return (
    <div className="main-content flex flex-col gap-24">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "-12px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Go Back</span>
      </Button>

      {loading && (
        <div className="flex flex-col items-center justify-center">
          <Loader title="Please wait..." />
        </div>
      )}

      {patient && (
        <React.Fragment>
          <Card
            content={
              <div className="flex flex-col">
                <div className="flex flex-col items-center gap-12" style={{ marginTop: "32px" }}>
                  <Svg name="profile" style={{ width: "72px", height: "72px" }} />

                  {patient && (
                    <div
                      style={{ padding: "8px 12px" }}
                      className="flex items-center flex-col gap-12"
                    >
                      <div className="text-xxl text-bold">
                        {patient.firstName} {patient.lastName}
                      </div>
                      <div className="sub-text text-xs text-regular" style={{ marginTop: "-6px" }}>
                        {patient.email}
                        {" • "}
                        {patient.phone}
                        {" • "}
                        Registered:{" "}
                        {moment(getEpochDate(patient.registeredAt)).format(dateTimeFormat)}
                      </div>

                      <div className="flex gap-12 items-center text-xs">
                        <div
                          className={`badge ${patient.status == "enabled" ? "success" : "error"}`}
                        >
                          {patient.status == "enabled" ? "Enabled" : "Disabled"}
                        </div>
                        {patient && patient.deletedAt > 0 && (
                          <React.Fragment>
                            <span>{"•"}</span>
                            <div className="sub-text text-xs">Deleted</div>
                          </React.Fragment>
                        )}
                        <span>{"•"}</span>
                        <div className="sub-text text-xs text-regular">
                          <Rating
                            start={0}
                            stop={5}
                            step={1}
                            style={{ marginLeft: "4px" }}
                            initialRating={patient.rating}
                            emptySymbol="fa fa-star-o theme-text"
                            fullSymbol="fa fa-star theme-text"
                            readonly
                          />
                          {patient.rating > 0 && (
                            <span className="sub-text">{patient.rating.toFixed(1)}</span>
                          )}
                        </div>
                      </div>
                      {patient && !patient.deletedAt && (
                        <div className="flex gap-10 item-center" style={{ marginTop: "16px" }}>
                          <Button
                            disabled={loading}
                            bsStyle="danger"
                            outline="true"
                            onClick={
                              patient.status == "enabled"
                                ? () => disableAccount()
                                : () => enableAccount()
                            }
                          >
                            {patient.status == "enabled" ? "Disable Account" : "Enable Account"}
                          </Button>

                          <Button
                            disabled={loading}
                            bsStyle="danger"
                            outline="true"
                            title="Delete Account"
                            onClick={() => deleteAccount()}
                          >
                            <Svg name="trash" className="w-4 h-4" />
                            Delete Account
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            }
          />

          <Card
            title={
              <div className="flex items-center">
                <div className="title">Profile Information</div>
                {editMode && (
                  <div className="flex items-center gap-10 ml-auto">
                    <Button
                      disabled={loading}
                      bsStyle="danger"
                      outline="true"
                      style={{ marginLeft: "auto" }}
                      onClick={() => cancelChanges()}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={loading || !firstName || !lastName || !dateOfBirth || !address}
                      loading={loading}
                      bsStyle="danger"
                      fill
                      onClick={() => updateUserInfo()}
                    >
                      Save Changes
                    </Button>
                  </div>
                )}
                {!editMode && patient && !patient.deletedAt && (
                  <Button
                    bsStyle="danger"
                    disabled={editMode || loading}
                    fill
                    style={{ marginLeft: "auto" }}
                    onClick={(e) => setEditMode(true)}
                  >
                    <Svg name="edit" className="w-4 h-4" />
                    Edit
                  </Button>
                )}
              </div>
            }
            content={
              <div className="flex flex-col gap-16">
                <Card
                  content={
                    <div
                      className="grid"
                      style={{
                        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
                        gridColumnGap: "16px",
                      }}
                    >
                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            label: "First name",
                            type: "text",
                            maxLength: "32",
                            bsClass: "form-control",
                            placeholder: "First Name",
                            value: firstName,
                            onChange: (e) => {
                              setFirstName(e.target.value.replace(/[^a-z ]/gi, ""));
                            },
                            disabled: !editMode,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            label: "Last name",
                            type: "text",
                            maxLength: "32",
                            bsClass: "form-control",
                            placeholder: "Last Name",
                            value: lastName,
                            onChange: (e) => {
                              setLastName(e.target.value.replace(/[^a-z ]/gi, ""));
                            },
                            disabled: !editMode,
                          },
                        ]}
                      />
                      {/*
                      <div className="form-group">
                        <ControlLabel>Gender:</ControlLabel>
                        <Select
                          name="gender-select"
                          isDisabled={!editMode}
                          isClearable={false}
                          className="react-select react-select-icon"
                          isSearchable={true}
                          placeholder="Select Gender"
                          noOptionsMessage={(inp) => "No Genders Loaded"}
                          isMulti={false}
                          value={gender}
                          onChange={(opt) => setGender(opt)}
                          options={genders}
                          formatGroupLabel={(data) => (
                            <div className="flex items-center text-theme font-semibold">
                              {data.label}
                            </div>
                          )}
                        />
                      </div>*/}

                      <div className="form-group">
                        <ControlLabel>Date of Birth:</ControlLabel>
                        <ReactDatetime
                          dateFormat={dateFormat}
                          timeFormat={false}
                          isValidDate={disableFutureDt}
                          value={dateOfBirth}
                          closeOnSelect={true}
                          onChange={changeDate}
                          inputProps={{ disabled: !editMode, placeholder: "" }}
                        />
                      </div>

                      <div className="form-group">
                        <ControlLabel className="title">Address</ControlLabel>
                        <div className="flex items-center gap-12" style={{ height: "40px" }}>
                          {patient && !patient.deletedAt ? formatFullAddress(address) : "--"}
                          {editMode && (
                            <Button
                              disabled={!editMode}
                              bsStyle="danger"
                              outline="true"
                              style={{ marginLeft: "auto" }}
                              onClick={() => setLocationModalOpen(true)}
                            >
                              Search
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                />

                {/* <Card
                  content={
                    <div
                      className="grid"
                      style={{
                        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
                        gridColumnGap: "16px",
                      }}
                    >
                      <FormGroup>
                        <ControlLabel>
                          Health Card Number: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          disabled={!editMode}
                          type="text"
                          maxLength="50"
                          name="hcNumber"
                          value={hcNumber}
                          placeholder="Health Card Number"
                          onChange={(event) => {
                            setHcNumber(event.target.value);
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <ControlLabel>Expiry Date:</ControlLabel>
                        <div>
                          <ReactDatetime
                            dateFormat={dateFormat}
                            value={hcExpiryDate}
                            closeOnSelect={true}
                            inputProps={{
                              required: true,
                              placeholder: "Card Expiry Date (optional)",
                              disabled: !editMode,
                            }}
                            timeFormat={false}
                            isValidDate={isValidExpiryDate}
                            onChange={(e) => {
                              setHcExpiryDate(moment(e).toDate());
                            }}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <ControlLabel>
                          Province: <span className="star">*</span>
                        </ControlLabel>
                        <Select
                          isDisabled={!editMode}
                          name="province-select"
                          isClearable={false}
                          className="react-select react-select-icon"
                          isSearchable={true}
                          placeholder="Select Province"
                          noOptionsMessage={(inp) => "No Provinces Loaded"}
                          isMulti={false}
                          value={hcProvince}
                          onChange={(opt) => {
                            setHcProvince(opt);
                          }}
                          options={provinces}
                          formatGroupLabel={(data) => (
                            <div className="flex items-center text-theme font-semibold">
                              {data.label}
                            </div>
                          )}
                        />
                      </FormGroup>

                      <FormGroup>
                        <ControlLabel>
                          Health Card Picture: <span className="star">*</span>
                        </ControlLabel>
                        <div className="flex items-center">
                          <input
                            disabled={!editMode}
                            accept="image/png, image/jpeg"
                            ref={healthCardFileInput}
                            id="file-upload"
                            style={{ display: "none" }}
                            type="file"
                            onChange={onHealthCardFileChange}
                          />

                          {!healthCardFile && !healthCardFileId && (
                            <React.Fragment>
                              {editMode && (
                                <div className="flex items-center gap-6 w-full">
                                  {!healthCardFile && !healthCardFileId && (
                                    <div className="text-xs sub-text">Upload JPEG or PNG file</div>
                                  )}
                                  <Button
                                    fill
                                    bsStyle="danger"
                                    style={{ marginLeft: "auto" }}
                                    onClick={onHealthCardFileUpload}
                                  >
                                    <Svg name="upload" className="w-4 h-4" />
                                    <span>Upload</span>
                                  </Button>
                                </div>
                              )}
                              {!editMode && (
                                <div
                                  className="flex items-center text-xs sub-text"
                                  style={{ padding: "8px 0" }}
                                >
                                  No image attached
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>

                        {(healthCardFile || healthCardFileId) && (
                          <div className="flex items-center w-full" style={{ height: "40px" }}>
                            <div
                              className="text-semibold main-text flex items-center"
                              style={{
                                maxWidth: "75%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxHeight: "18px",
                              }}
                            >
                              {healthCardFile ? healthCardFile.name : "Picture Attached"}
                              {healthCardFileId && !healthCardFile && (
                                <Button
                                  simple
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px 6px",
                                    height: "16px",
                                    marginLeft: "12px",
                                  }}
                                  onClick={() => {
                                    setHealthCardKey(healthCardFileId);
                                  }}
                                >
                                  <Svg
                                    name="eye"
                                    style={{ marginRight: "-2px" }}
                                    className="w-4 h-4"
                                  />
                                  View Card
                                </Button>
                              )}
                            </div>
                            {editMode && (
                              <Button
                                fill
                                bsStyle="danger"
                                style={{ marginLeft: "auto" }}
                                onClick={() => {
                                  setHealthCardFile(null);
                                  setHealthCardFileId(null);
                                }}
                              >
                                <Svg name="trash" className="w-4 h-4" />
                              </Button>
                            )}
                          </div>
                        )}
                      </FormGroup>
                    </div>
                  }
                /> */}
                {false && (
                  <Card
                    content={
                      <div
                        className="grid"
                        style={{
                          gridTemplateColumns: "repeat(2,minmax(0,1fr))",
                          gridColumnGap: "16px",
                        }}
                      >
                        <FormGroup>
                          <ControlLabel>
                            Insurance Provider: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            disabled={!editMode}
                            type="text"
                            maxLength="100"
                            name="insuranceProvider"
                            value={insuranceProvider}
                            onChange={(event) => {
                              setInsuranceProvider(event.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Insurance Policy Number: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            disabled={!editMode}
                            type="text"
                            maxLength="100"
                            name="insuranceNumber"
                            value={insuranceNumber}
                            onChange={(event) => {
                              setInsuranceNumber(event.target.value);
                            }}
                          />
                        </FormGroup>
                      </div>
                    }
                  />
                )}

                <Card
                  content={
                    <div className="flex flex-col">
                      <FormGroup>
                        <ControlLabel>Issues and Allergies:</ControlLabel>
                        <FormControl
                          disabled={!editMode}
                          type="text"
                          maxLength="500"
                          name="insuranceProvider"
                          value={allergies}
                          onChange={(event) => {
                            setAllergies(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </div>
                  }
                />
              </div>
            }
          />

          <Card
            title={
              <div className="flex items-center">
                <div className="title">Admin Notes</div>
                {notesEditMode && (
                  <div className="flex items-center gap-10 ml-auto">
                    <Button
                      disabled={loading}
                      bsStyle="danger"
                      outline="true"
                      style={{ marginLeft: "auto" }}
                      onClick={() => {
                        setNotesEditMode(false);
                        setAdminNotes(patient.adminNotes);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={loading}
                      loading={loading}
                      bsStyle="danger"
                      fill
                      onClick={() => onSaveNotes()}
                    >
                      Save Notes
                    </Button>
                  </div>
                )}
                {!notesEditMode && patient && !patient.deletedAt && (
                  <Button
                    bsStyle="danger"
                    disabled={loading}
                    fill
                    style={{ marginLeft: "auto" }}
                    onClick={(e) => setNotesEditMode(true)}
                  >
                    <Svg name="edit" className="w-4 h-4" />
                    Edit
                  </Button>
                )}
              </div>
            }
            className="w-full"
            content={
              <div className="flex flex-col">
                <FormControl
                  type="textarea"
                  maxLength={500}
                  componentClass="textarea"
                  rows={5}
                  readOnly={!notesEditMode}
                  onChange={(event) => {
                    setAdminNotes(event.target.value);
                  }}
                  value={adminNotes}
                />
              </div>
            }
          />

          {patientId && (
            <Card
              title="Booked Appointments"
              content={<PatientAppointments pageSize={8} patientId={patientId} />}
            />
          )}

          {patientId && (
            <Card
              title="Reviews"
              content={
                <UserReviews
                  onUpdate={(userData) => {
                    setPatient(userData);
                  }}
                  pageSize={8}
                  patientId={patientId}
                />
              }
            />
          )}

          <AddLocationModal
            show={locationModalOpen}
            onHide={() => setLocationModalOpen(false)}
            address={address}
            onSelectAddress={onAddressSelected}
          />

          <HealthCardPreviewModal
            show={healthCardKey != null}
            formKey={healthCardKey}
            onHide={() => setHealthCardKey(null)}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default UserDetails;
