import React from "react";
import "./timeline.css";
import moment from "moment";
import { dateTimeFormat } from "api/common";

export default function Timeline({ milestones }) {
  if (!Array.isArray(milestones)) {
    return null;
  }

  const [totalSteps, totalStepsCompleted, lastCompleted] = milestones.reduce(
    (prev, m, i) => [
      prev[0] + m.steps,
      prev[1] + m.stepsCompleted,
      m.steps === m.stepsCompleted ? i : prev[2],
    ],
    [0, 0, -1]
  );

  return (
    <div className="timeline">
      <div className="line">
        <div
          className="line-progression"
          style={{ width: `${(totalStepsCompleted / totalSteps) * 100}%` }}
        ></div>
      </div>
      <ol>
        {milestones.map((m, i) => (
          <li
            className="milestone"
            style={{ width: `${(m.steps / totalSteps) * 100}%` }}
            key={`{m.label}{i}-milestone`}
          >
            <div className="timeline-content">
              <span
                className={`timeline-status${
                  m.steps === m.stepsCompleted ? " status--checked" : ""
                }`}
              />
              {lastCompleted === i && <div className="pulse"></div>}
              <span className="milestone-label">
                {`${m.label}${m.date ? ` ${moment(m.date).format(dateTimeFormat)}` : ""}`}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}

export function TimelineSVG({ milestones }) {
  if (!Array.isArray(milestones)) {
    return null;
  }

  const [totalSteps, totalStepsCompleted, lastCompleted] = milestones.reduce(
    (prev, m, i) => [
      prev[0] + m.steps,
      prev[1] + m.stepsCompleted,
      m.steps === m.stepsCompleted ? i : prev[2],
    ],
    [0, 0, -1]
  );
  let cumulatedSteps = 0;
  return (
    <svg width="100%" height="110px" className="timeline-svg">
      <defs>
        <linearGradient id="line-gradient" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#189A62" />
          <stop offset="100%" stopColor="#002766" />
        </linearGradient>
      </defs>
      <mask id="mask-progression" maskContentUnits="objectBoundingBox">
        <rect className="mask-line" x="-1" y="0" height="1" width="1" fill="white" />
      </mask>
      <rect y="25" height="2" width="100%" fill="lightgrey" />
      <rect
        id="line-progression"
        y="25"
        height="2"
        width={`${(totalStepsCompleted / totalSteps) * 100}%`}
        fill="url(#line-gradient)"
        mask="url(#mask-progression)"
      />
      {milestones.map((m, i) => {
        const posX = `${((m.steps + cumulatedSteps) / totalSteps) * 100}%`;
        const circle = (
          <React.Fragment key={i}>
            {!m.finish && (
              <circle
                cx={posX}
                cy="26"
                r="4.5"
                fill={
                  m.steps === m.stepsCompleted
                    ? m.breakPoint
                      ? "#dd3333"
                      : m.success
                      ? "#34cb6b"
                      : "#189A62"
                    : "lightgrey"
                }
              />
            )}
            {lastCompleted === i && (
              <circle
                className="svg-pulse"
                transform-origin={`${posX} 26`}
                cx={posX}
                cy="26"
                r="4.5"
                fill={m.breakPoint ? "#dd3333" : m.success ? "#34cb6b" : "#189A62"}
              />
            )}
            <text textAnchor="middle" fontFamily="Inter" fontSize="13" x={posX} y="64">
              <tspan>{m.label}</tspan>
              {m.date && (
                <tspan fontSize="12" fill="#888888" x={posX} dy="22">
                  {moment(m.date).format(dateTimeFormat)}
                </tspan>
              )}
            </text>
          </React.Fragment>
        );
        cumulatedSteps += m.steps;
        return circle;
      })}
    </svg>
  );
}
