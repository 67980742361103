import React, { Component } from "react"
import { Collapse } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx"
import Svg from "components/Svg/Svg"
import routes from "routes.js"
import { getUser } from "api/auth"

var ps

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getCollapseStates(routes),
      isWindows: navigator.platform.indexOf("Win") > -1,
      width: window.innerWidth,
    }
  }

  getCollapseStates = (routes) => {
    let initialState = {}
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        }
      }
      return null
    })
    return initialState
  }

  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }

  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null
      }

      if (prop.collapse) {
        var st = {}
        st[prop["state"]] = !this.state[prop.state]
        return (
          <li className={this.getCollapseInitialState(prop.views) ? "active" : ""} key={key}>
            <a
              onClick={(e) => {
                e.preventDefault()
                this.setState(st)
              }}>
              <Svg name={prop.icon} className="w-5 h-5" />
              <p>
                {prop.name}
                <b className={this.state[prop.state] ? "caret rotate-180" : "caret"} />
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        )
      }

      return (
        <React.Fragment key={key}>
          <li className={this.activeRoute(prop.layout + prop.path)}>
            <NavLink to={prop.layout + prop.path} className="nav-link" activeClassName="active">
              {prop.icon ? (
                <React.Fragment>
                  {prop.path === "/messaging" && this.props.unreadMessagesCount > 0 && <span className="messaging-badge"></span>}
                  <Svg name={prop.icon} className="w-5 h-5" />
                  <p>{prop.name}</p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className="sidebar-mini">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </React.Fragment>
              )}
            </NavLink>
          </li>

          {["/medical-notes", "/company-earnings", "/messaging", "/drop-off", "/notifications"].includes(prop.path) ? (
            <div className="separator horizontal"></div>
          ) : undefined}
        </React.Fragment>
      )
    })
  }

  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth })
  }

  componentDidUpdate() {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update()
      }, 350)
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions.bind(this))
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1 && ps) {
      ps.destroy()
    }
  }

  render() {
    return (
      <div className="sidebar tap-sidebar" ref="sidebarWrapper">
        <div className="logo" style={{ boxShadow: "none" }}>
          <a href="/" title="Avvy Admin Panel">
            <div className="main-text flex flex-col items-center " style={{ marginTop: "12px", marginBottom: "-10px" }}>
              <Svg name="logo_horizontal" className="avvy-sidebar-large-icon" alt="Avvy Logo" />
              <Svg className="avvy-sidebar-mini-icon" name="logo" alt="Avvy Logo" style={{ width: "32px", height: "32px" }} />
            </div>
          </a>
        </div>
        <div className="separator horizontal" style={{ margin: "20px 10px" }}></div>
        <div className="sidebar-wrapper">
          <ul className="nav sidebar-links">
            {this.state.width <= 992 ? <AdminNavbarLinks /> : null}
            {this.createLinks(routes)}
          </ul>
        </div>
      </div>
    )
  }
}

export default Sidebar
