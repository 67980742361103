import React, { Component } from "react"
import axios from "axios"
import { FormGroup, ControlLabel, FormControl, Row, Col } from "react-bootstrap"
import Swal from "sweetalert2"
import Card from "components/Card/Card.jsx"
import Button from "components/CustomButton/CustomButton.jsx"
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input"
import "react-phone-number-input/style.css"
import AddLocationModal from "../../Components/AutocompleteWithMap/AddLocationModal.jsx"
import Svg from "components/Svg/Svg.jsx"
import MembersTable from "./MembersTable.jsx"
import CorporateAppointments from "../Appointments/AppointmentsTable.jsx"
import CorporateStats from "./CorporateStats.jsx"
import { parseError } from "api/common.js"
import AdminMultiInvite from "./AdminMultiInvite.jsx"

class EditCorpClient extends Component {
  constructor(props) {
    super(props)
    this.state = {
      company: "",
      companyId: "",
      initialCompany: "",
      companyError: "",
      email: "",
      emailError: "",
      phone: "",
      phoneError: "",
      disabled: false,
      place: null,
      addressError: "",
      address: "",
      AddLocationModalOpen: false,
      latitude: 0,
      longitude: 0,
      disableBtn: false,
      editProfileOn: false,
      notes: "",
      status: "",
      loading: false,
      billedExternally: false,
      admins: [],
      AdminMultiInviteOpen: false,
    }
  }

  componentDidMount() {
    this.getCorporateUser()
  }

  getCorporateUser = () => {
    const self = this
    const id = this.props.match.params.id

    this.setState({ loading: true })

    axios
      .get(`${process.env.REACT_APP_API_URL}/corporateclients/${id}`)
      .then((res) => {
        self.setState({ loading: false })
        self.setAccountData(res.data)
      })
      .catch((err) => {
        let error = parseError(err)
        self.setState({ loading: false })
        Swal.fire("Corporate Account Error", error, "error")
      })
  }

  setAccountData = (data) => {
    this.setState({
      company: data.name,
      companyId: data.id,
      initialCompany: data.name,
      phone: data.phone,
      initialPhone: data.phone,
      email: data.email,
      initialEmail: data.email,
      initialAddress: `${data.address.street} ${data.address.city}, ${data.address.province} ${data.address.postalCode}`,
      address: `${data.address.street} ${data.address.city}, ${data.address.province} ${data.address.postalCode}`,
      addressData: data.address,
      province: data.address.province,
      city: data.address.city,
      street: data.address.street,
      postalCode: data.address.postalCode,
      latitude: data.location.latitude,
      initialLatitude: data.location.latitude,
      longitude: data.location.longitude,
      initialLongitude: data.location.longitude,
      status: data.status,
      billedExternally: data.isBilledExternally,
      initialNotes: data.notes || "",
      notes: data.notes || "",
      admins: data.admins || [],
    })
  }

  getAddress = (value) => {
    this.setState({ address: value })
  }

  getAddressData = (value) => {
    this.setState({
      addressData: value,
      province: value.province,
      city: value.city,
      street: value.street,
      postalCode: value.postalCode,
      latitude: value.latitude,
      longitude: value.longitude,
    })
  }

  onUpdateCorporateClient = () => {
    const self = this
    this.setState({ loading: true })

    const data = {
      name: self.state.company,
      phone: self.state.phone,
      address: {
        province: self.state.province,
        city: self.state.city,
        street: self.state.street,
        postalCode: self.state.postalCode,
      },
      latitude: self.state.latitude,
      longitude: self.state.longitude,
      notes: self.state.notes,
    }

    axios
      .put(`${process.env.REACT_APP_API_URL}/CorporateClients/${this.state.companyId}`, data)
      .then((res) => {
        self.setState({
          loading: false,
          editProfileOn: !self.state.editProfileOn,
        })
        self.setAccountData(res.data)
        Swal.fire("Account Update", "Company information has been updated successfully.", "success")
      })
      .catch((err) => {
        let error = parseError(err)
        this.setState({ loading: false })
        Swal.fire("Account Error", error, "error")
      })
  }

  cancelChanges = () => {
    this.setState({
      loading: false,
      company: this.state.initialCompany,
      address: this.state.initialAddress,
      phone: this.state.initialPhone,
      latitude: this.state.initialLatitude,
      longitude: this.state.initialLongitude,
      email: this.state.initialEmail,
      notes: this.state.initialNotes,
      editProfileOn: false,
    })
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  disable = async () => {
    const self = this

    Swal.fire({
      title: "Are you sure you want to disable this account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Disable Account",
    }).then((result) => {
      if (result.isConfirmed) {
        self.setState({ loading: true })

        axios
          .put(`${process.env.REACT_APP_API_URL}/CorporateClients/${self.state.companyId}/disable`)
          .then((res) => {
            self.setState({ status: "disabled", loading: false })
            Swal.fire({
              title: "Account has been disabled.",
              icon: "success",
              showCancelButton: false,
            })
          })
          .catch((err) => {
            let error = parseError(err)
            self.setState({ loading: false })
            Swal.fire("Account Error", error, "error")
          })
      }
    })
  }

  enable = async () => {
    const self = this

    Swal.fire({
      title: "Are you sure you want to enable this account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Enable Account",
    }).then((result) => {
      if (result.isConfirmed) {
        self.setState({ loading: true })

        axios
          .put(`${process.env.REACT_APP_API_URL}/CorporateClients/${self.state.companyId}/enable`)
          .then((res) => {
            Swal.fire({
              title: "Account has been enabled.",
              icon: "success",
              showCancelButton: false,
            }).then(() => {
              self.setState({ status: "enabled", loading: false })
            })
          })
          .catch((err) => {
            let error = parseError(err)
            self.setState({ loading: false })
            Swal.fire("Account Error", error, "error")
          })
      }
    })
  }

  render() {
    return (
      <div className="flex flex-col main-content gap-24">
        <Button
          simple
          icon
          style={{ padding: "0", marginBottom: "-12px", marginRight: "auto" }}
          onClick={() => {
            // this.props.history.go(-1);
            this.props.history.push({
              pathname: `/admin/corporate`,
              state: {
                // pageToken: this.props.location.state.pageToken ? this.props.location.state.pageToken : null,
                // isBackward: this.props.location.state.isBackward ? this.props.location.state.isBackward : null,
              },
            })
          }}>
          <Svg name="chevron-left" className="w-6 h-6" />
          <span className="text-bold">Go Back</span>
        </Button>

        <Card
          title={
            <div className="flex items-center">
              <div className="title">Corporate Account</div>

              <Button
                bsStyle="danger"
                disabled={this.state.editProfileOn || this.state.loading}
                fill
                style={{ marginLeft: "auto" }}
                onClick={(e) => this.setState({ editProfileOn: true })}>
                <Svg name="edit" className="w-4 h-4" />
                Edit
              </Button>
            </div>
          }
          content={
            <div className="flex flex-col gap-24 items-center">
              <div className="flex flex-col items-center gap-10">
                <div className="text-xxl text-semibold">{this.state.initialCompany}</div>
                <div className="flex items-center gap-10">
                  <small className="sub-text text-normal">{this.state.email}</small>
                  <span>{" • "}</span>
                  <div className={`badge ${this.state.status == "enabled" ? "success" : "error"}`}>{this.state.status}</div>
                </div>
                <div className="flex flex-col items-center gap-10">
                  {this.state.admins.length > 1 && <div>Other Registered Admins:</div>}
                  {this.state.admins.length > 1 &&
                    this.state.admins.map((admin) => {
                      if (admin.email !== this.state.email) {
                        return (
                          <small key={admin.id} className="sub-text text-normal">
                            {admin.email}
                          </small>
                        )
                      }
                    })}
                </div>
                <div className="sub-text text-normal">{this.state.billedExternally ? "Billed Externally" : "Billed Internally (Stripe)"}</div>

                <Button
                  style={{ marginTop: 5 }}
                  disabled={this.state.loading}
                  bsStyle="primary"
                  // variant="primary"
                  outline="true"
                  onClick={() => {
                    this.setState({ AdminMultiInviteOpen: true })
                  }}>
                  Invite More Admins
                </Button>

                <Button
                  style={{ marginTop: 5 }}
                  disabled={this.state.loading}
                  bsStyle="danger"
                  outline="true"
                  onClick={this.state.status == "enabled" ? () => this.disable() : () => this.enable()}>
                  {this.state.status == "enabled" ? "Disable Account" : "Enable Account"}
                </Button>
              </div>

              <div className="separator horizontal" style={{ maxWidth: "500px", width: "100%" }}></div>

              <div className="grid grid-2col gap-12 flex-col" style={{ maxWidth: "900px", width: "100%" }}>
                <FormGroup>
                  <ControlLabel>
                    Company name: <span className="star">*</span>
                  </ControlLabel>
                  <FormControl
                    type="text"
                    name="company"
                    disabled={!this.state.editProfileOn}
                    value={this.state.company}
                    onChange={(event) => {
                      this.setState({ company: event.target.value })
                    }}
                    onBlur={(event) => {
                      event.target.value === ""
                        ? this.setState({
                            companyError: <small className="text-danger">Company name is required.</small>,
                          })
                        : this.setState({ companyError: null })
                    }}
                  />
                  {this.state.companyError}
                </FormGroup>
                <div className="form-group phone-input">
                  <ControlLabel>
                    Phone Number: <span className="star">*</span>
                  </ControlLabel>
                  <PhoneInput
                    maxLength="16"
                    max="10"
                    defaultCountry="CA"
                    extension="true"
                    disabled={!this.state.editProfileOn}
                    value={this.state.phone}
                    onChange={(value) => {
                      this.setState({ phone: value })
                      if (value && !isPossiblePhoneNumber(value)) {
                        this.setState({
                          phoneError: <small className="text-danger">A valid Canadian or US phone number is required.</small>,
                        })
                      } else {
                        this.setState({ phoneError: null })
                      }
                    }}
                  />
                  {this.state.phoneError}
                </div>
                <Row style={{ marginBottom: 10 }}>
                  <Col md={12} className="form-group">
                    <ControlLabel className="title">Address</ControlLabel>
                    <div>{this.state.address}</div>
                  </Col>
                  {this.state.editProfileOn && (
                    <Col md={12} style={{ marginBottom: 20 }}>
                      <Button
                        disabled={!this.state.editProfileOn}
                        bsStyle="danger"
                        outline="true"
                        onClick={() => this.setState({ AddLocationModalOpen: true })}>
                        Select Address
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Notes:</ControlLabel>
                      <FormControl
                        disabled={!this.state.editProfileOn}
                        type="textarea"
                        maxLength={500}
                        componentClass="textarea"
                        rows={3}
                        value={this.state.notes}
                        onChange={(event) => {
                          this.setState({ notes: event.target.value })
                        }}
                        onKeyPress={(event) => this.setState({ notes: event.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {this.state.editProfileOn ? (
                  <Row className="flex items-center gap-10 col-2span">
                    <Button disabled={this.state.loading} bsStyle="danger" outline="true" style={{ marginLeft: "auto" }} onClick={() => this.cancelChanges()}>
                      Cancel
                    </Button>
                    <Button
                      // disabled={this.state.loading}
                      loading={this.state.loading}
                      bsStyle="danger"
                      fill
                      disabled={
                        this.state.loading || !this.state.company || !this.state.phone || !this.state.address || !this.state.latitude || !this.state.longitude
                      }
                      onClick={() => this.onUpdateCorporateClient()}>
                      Save Changes
                    </Button>
                  </Row>
                ) : null}
              </div>
            </div>
          }
        />

        <CorporateStats clientId={this.props.match.params.id} />

        {this.state.admins.length === 0 && !this.state.loading && (
          <Card title="Booked Appointments" content={<div className="text-center sub-text">Corporate client has not accepted their invitation yet.</div>} />
        )}

        {this.state.admins.length > 0 && (
          <Card title="Booked Appointments" content={<CorporateAppointments pageSize={8} patientId={this.state.admins[0].id} />} />
        )}

        <MembersTable clientId={this.props.match.params.id} />

        <AddLocationModal
          show={this.state.AddLocationModalOpen}
          onHide={() => this.setState({ AddLocationModalOpen: false })}
          address={this.state.address}
          onSelectAddress={this.getAddress}
          onSelectAddressData={this.getAddressData}
        />
        {this.state.AdminMultiInviteOpen && (
          <AdminMultiInvite show={this.state.AdminMultiInviteOpen} setShow={(value) => this.setState({ AdminMultiInviteOpen: value })} />
        )}
      </div>
    )
  }
}

export default EditCorpClient
