import React, { useState } from "react"
import ReactTable from "react-table"
import Card from "components/Card/Card.jsx"
import Button from "components/CustomButton/CustomButton.jsx"
import axios from "axios"
import moment from "moment"
import { parseError } from "api/common.js"
import { debounce } from "debounce"
import Swal from "sweetalert2"
import Svg from "components/Svg/Svg.jsx"
import AddPersonModal from "../Appointments/AddPersonModal.jsx"
import { dateFormat } from "api/common.js"

const MembersTable = (props) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [previousPageToken, setPreviousPageToken] = useState(null)
  const [nextPageToken, setNextPageToken] = useState(null)
  const [pageSize] = useState(10)
  const [error, setError] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const getMembers = (pageToken = null, isBackward = null) => {
    setLoading(true)
    setError(null)
    setData([])

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/corporateclients/${encodeURIComponent(props.clientId)}/members?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward ? "true" : "false"}`
      )
      .then((res) => {
        res.data.items.forEach((p) => {
          p.gender = { value: p.gender }
          p.dob = moment(`${p.dateOfBirth.day}/${p.dateOfBirth.month}/${p.dateOfBirth.year}`, "D/M/YYYY").toDate()
        })

        setNextPageToken(res.data.nextPageToken)
        setPreviousPageToken(res.data.previousPageToken)
        setData(mapItems(res.data.items))
        setLoading(false)
      })
      .catch((err) => {
        let error = parseError(err)
        setLoading(false)
        setError(error)
      })
  }

  const fetchData = (state) => {
    getMembers()
  }

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapUser(prop)
    })
  }

  const mapUser = (prop) => {
    return {
      id: prop.id,
      firstName: prop.firstName,
      lastName: prop.lastName,
      email: prop.email,
      phone: prop.phone,
      dob: formatDate(prop.dateOfBirth),
      selection:
        props.selectable === true ? (
          <div className="flex items-center">
            <input
              style={{ margin: "0 0 0 4px" }}
              name={prop.id}
              type="radio"
              // name="patient-select-group"
              checked={selectedUser && selectedUser.id === prop.id}
              onChange={(e) => {
                if (e.target.checked && props.onSelected) {
                  setSelectedUser(prop)
                  props.onSelected(prop)
                }
              }}
            />
          </div>
        ) : null,
      actions: (
        <div className="flex items-center gap-6">
          <Button
            bsStyle="danger"
            outline="true"
            style={{ padding: "7px 10px" }}
            title="View or Edit Details"
            onClick={() => {
              setSelectedUser(prop)
              setShowEditModal(true)
            }}>
            <Svg name="edit" className="w-4 h-4" />
          </Button>
          <Button bsStyle="danger" outline="true" title="Delete Member" style={{ padding: "7px 10px" }} onClick={() => onDelete(prop)}>
            <Svg name="trash" className="w-4 h-4" />
          </Button>
        </div>
      ),
    }
  }

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--"
    }

    let dateString = moment(`${inDate.day}/${inDate.month}/${inDate.year}`, "D/M/YYYY").format(dateFormat)
    return dateString
  }

  const onPersonAddedOrUpdated = (person) => {
    let isUpdate = person.id && data.find((s) => s.id === person.id)

    const requestOptions = {
      method: isUpdate ? "PUT" : "POST",
      url: isUpdate
        ? `${process.env.REACT_APP_API_URL}/corporateclients/${encodeURIComponent(props.clientId)}/members/${encodeURIComponent(person.id)}`
        : `${process.env.REACT_APP_API_URL}/corporateclients/${encodeURIComponent(props.clientId)}/members`,
      data: {
        firstName: person.firstName,
        lastName: person.lastName,
        gender: person.gender.value,
        phone: person.phone,
        id: person.id || null,
        email: person.email,
        dateOfBirth: {
          year: person.dob.getFullYear(),
          month: person.dob.getMonth(),
          day: person.dob.getDate(),
        },
      },
    }

    setLoading(true)

    axios(requestOptions)
      .then((res) => {
        setLoading(false)
        getMembers()
        Swal.fire(
          isUpdate ? "Member Update" : "New Member",
          isUpdate ? "Member details have been updated successfully." : "New member has been created successfully.",
          "success"
        )
      })
      .catch((err) => {
        let error = parseError(err)
        setLoading(false)
        Swal.fire("Member Error", error, "error")
      })
  }

  const onDelete = (person) => {
    const self = this

    Swal.fire({
      title: `Are you sure you want to delete the member details for '${person.firstName} ${person.lastName}'?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Member",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true)

        axios
          .delete(`${process.env.REACT_APP_API_URL}/corporateclients/${encodeURIComponent(props.clientId)}/members/${encodeURIComponent(person.id)}`)
          .then((res) => {
            setLoading(false)
            getMembers()
            Swal.fire({
              title: "Member details have been successfully deleted.",
              icon: "success",
              showCancelButton: false,
            })
          })
          .catch((err) => {
            let error = parseError(err)
            setLoading(false)
            Swal.fire("Member Error", error, "error")
          })
      }
    })
  }

  return (
    <Card
      title="Corporate Members"
      category="To pre-fill patient details when booking"
      content={
        <div className="flex flex-col gap-16">
          <ReactTable
            loading={loading}
            loadingText="Loading members..."
            noDataText={error ? error : loading ? "" : "No members found..."}
            data={data}
            onFetchData={debounce(fetchData, 800)}
            columns={[
              props.selectable === true
                ? {
                    Header: "",
                    accessor: "selection",
                    sortable: false,
                    filterable: false,
                    minWidth: 40,
                    maxWidth: 40,
                  }
                : { minWidth: 0, maxWidth: 0 },
              {
                Header: "First Name",
                accessor: "firstName",
                sortable: false,
              },
              {
                Header: "Last Name",
                accessor: "lastName",
                sortable: false,
              },
              {
                Header: "Email",
                accessor: "email",
                sortable: false,
              },
              {
                Header: "Phone",
                accessor: "phone",
                sortable: false,
                maxWidth: 120,
              },
              {
                Header: "Date Of Birth",
                accessor: "dob",
                sortable: false,
                filterable: false,
                maxWidth: 120,
              },
              !props.selectable
                ? {
                    Header: "",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    minWidth: 100,
                    maxWidth: 100,
                  }
                : { minWidth: 0, maxWidth: 0 },
            ]}
            defaultPageSize={pageSize}
            showPaginationBottom={false}
            className="-striped -highlight"
          />
          <div className="flex items-center" style={{ marginLeft: "auto" }}>
            <Button
              className="pagination-btn left btn-fill"
              disabled={!previousPageToken || loading}
              onClick={() => getMembers(previousPageToken, true)}
              bsStyle="default"
              fill>
              <Svg name="chevron-left" className="w-6 h-6" />
            </Button>
            <Button
              className="pagination-btn right btn-fill"
              disabled={!nextPageToken || loading}
              onClick={() => getMembers(nextPageToken, false)}
              bsStyle="default"
              fill>
              <Svg name="chevron-right" className="w-6 h-6" />
            </Button>
          </div>

          <AddPersonModal
            mode="new"
            show={showEditModal}
            person={selectedUser}
            onHide={() => {
              setShowEditModal(false)
              setSelectedUser(null)
            }}
            onConfirm={onPersonAddedOrUpdated}
          />
        </div>
      }
    />
  )
}

export default MembersTable
