import React, { useState, useEffect, useRef } from "react"
import ReactTable from "react-table"
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap"
import Card from "components/Card/Card.jsx"
import Button from "components/CustomButton/CustomButton.jsx"
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input"
import ReactDatetime from "react-datetime"
import Select from "react-select"
import axios from "axios"
import moment from "moment"
import { parseError } from "api/common.js"
import { debounce } from "debounce"
import Svg from "components/Svg/Svg.jsx"
import "react-phone-number-input/style.css"
import { dateFormat } from "api/common"
import { formatCityAddress } from "api/common"

const AddPatientModal = (props) => {
  const healthCardFileInput = useRef()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [gender, setGender] = useState({ value: "" })
  const [dob, setDob] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [genders, setGenders] = useState([])
  const [hcExpiryDate, setHcExpiryDate] = useState(null)
  const [hcNumber, setHcNumber] = useState("")
  const [hcProvince, setHcProvince] = useState({ value: "" })
  const [provinces, setProvinces] = useState([])
  const [insuranceProvider, setInsuranceProvider] = useState("")
  const [insuranceNumber, setInsuranceNumber] = useState("")
  const [allergies, setAllergies] = useState("")
  const [healthCardFile, setHealthCardFile] = useState(null)
  const [healthCardFileId, setHealthCardFileId] = useState(null)

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [pageSize] = useState(8)
  const [previousPageToken, setPreviousPageToken] = useState(null)
  const [nextPageToken, setNextPageToken] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    if (props.show && props.mode === "new") {
      // axios
      //   .get(`${process.env.REACT_APP_API_URL}/account/genders`)
      //   .then((res) => {
      //     setGenders(res.data);

      //     if (props.person) {
      //       let gnd = res.data.find((s) => s.key === props.person.gender.value);
      //       if (gnd) {
      //         setGender({ value: gnd.key, label: gnd.value });
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      axios
        .get(`${process.env.REACT_APP_API_URL}/platform/provinces`)
        .then((res) => {
          let provinceList = res.data.map((p) => {
            return {
              label: p.value,
              value: p.key,
            }
          })
          setProvinces(provinceList)

          if (props.person && props.person.healthCareCard && props.person.healthCareCard.province) {
            let prv = provinceList.find((s) => s.value === props.person.healthCareCard.province)
            if (prv) {
              setHcProvince(prv)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    setFirstName(props.person ? props.person.firstName : "")
    setLastName(props.person ? props.person.lastName : "")
    setDob(props.person ? props.person.dob : null)
    setEmail(props.person ? props.person.email : "")
    setGender(props.person ? props.person.gender : { value: "" })
    setPhone(props.person ? props.person.phone : "")
    setHcNumber(props.person && props.person.healthCareCard ? props.person.healthCareCard.cardNumber : "")
    setHcProvince(props.person && props.person.healthCareCard ? { value: props.person.healthCareCard.province } : { value: "" })
    setHcExpiryDate(
      props.person && props.person.healthCareCard && props.person.healthCareCard.expiryDate
        ? moment(
            `${props.person.healthCareCard.expiryDate.day}/${props.person.healthCareCard.expiryDate.month}/${props.person.healthCareCard.expiryDate.year}`,
            "D/M/YYYY"
          ).toDate()
        : null
    )
    setHealthCardFileId(
      props.person && props.person.healthCareCard && props.person.healthCareCard.picture ? props.person.healthCareCard.picture.fileName : null
    )
    setInsuranceNumber(props.person && props.person.insurance ? props.person.insurance.policyNumber : "")
    setInsuranceProvider(props.person && props.person.insurance ? props.person.insurance.provider : "")
    setAllergies(props.person && props.person.issuesAllergies ? props.person.issuesAllergies : "")
    setEmailError(null)
    setPhoneError(null)
    setData([])
    setPreviousPageToken(null)
    setNextPageToken(null)
    setSelectedUser(null)
    setError(null)
    setLoading(false)
  }, [props.show])

  const handleEmailChange = (event) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let isValid = re.test(event.target.value) === true
    if (!isValid && event.target.value) {
      setEmailError(<small className="text-danger">Invalid email address format. Please make sure that you are using valid email address.</small>)
    } else {
      setEmailError(null)
    }
  }

  const handleBirthDayChange = (e) => {
    setDob(moment(e).toDate())
  }

  var isValidDob = function (current) {
    return current.isBefore(moment())
  }

  var isValidExpiryDate = function (current) {
    return current.isAfter(moment())
  }

  const onCreateAccount = () => {
    const obj =
      props.mode === "search"
        ? selectedUser
        : {
            id: props.person ? props.person.id : null,
            email: email,
            firstName: firstName,
            lastName: lastName,
            gender: { value: gender.value, label: gender.label },
            phone: phone,
            dob: dob,
            healthCareCard: {
              cardNumber: hcNumber,
              province: hcProvince.value,
              picture: {
                fileName: healthCardFileId || null,
              },
              pictureFile: healthCardFile || null,
              expiryDate: hcExpiryDate
                ? {
                    year: hcExpiryDate.getFullYear(),
                    month: hcExpiryDate.getMonth() + 1,
                    day: hcExpiryDate.getDate(),
                  }
                : null,
            },
            insurance: {
              provider: insuranceProvider,
              policyNumber: insuranceNumber,
            },
            issuesAllergies: allergies,
          }

    if (props.onConfirm) {
      props.onConfirm(obj)
    }

    props.onHide()
  }

  const getUsers = (pageToken = null, isBackward = null) => {
    setLoading(true)
    setError(null)
    setData([])

    axios
      .get(`${process.env.REACT_APP_API_URL}/account?pageSize=${pageSize}&paginationToken=${pageToken || ""}&isBackward=${isBackward ? "true" : "false"}`)
      .then((res) => {
        setNextPageToken(res.data.nextPageToken)
        setPreviousPageToken(res.data.previousPageToken)
        setData(mapItems(res.data.items))
        setLoading(false)
      })
      .catch((err) => {
        let error = parseError(err)
        setLoading(false)
        setError(error)
      })
  }

  const onSearch = (filter) => {
    setLoading(true)
    setData([])
    setError(null)

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/search`, filter)
      .then((res) => {
        setData(mapItems(res.data))
        setLoading(false)
      })
      .catch((err) => {
        let error = parseError(err)
        setLoading(false)
        setError(error)
      })
  }

  const getFilters = (filter) => {
    var dataFilter = {}

    filter.forEach((f) => {
      dataFilter[f.id] = f.value
    })

    return dataFilter
  }

  const fetchData = (state) => {
    if (state.filtered.length > 0) {
      let dataFilter = getFilters(state.filtered)
      onSearch(dataFilter)
    } else {
      getUsers()
    }
  }

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapUser(prop)
    })
  }

  const bypassFilter = (filter, row, column) => {
    return true
  }

  const mapUser = (prop) => {
    return {
      id: prop.id,
      firstName: prop.firstName,
      lastName: prop.lastName,
      email: prop.email,
      phone: prop.phone,
      status: <div className={`badge ${prop.status === "enabled" ? "success" : "error"}`}>{prop.status}</div>,
      address: formatCityAddress(prop.address),
      adminNotes: prop.adminNotes,
      actions: (
        <div className="flex items-center">
          <input
            style={{ margin: "0 0 0 4px" }}
            name={prop.id}
            type="radio"
            checked={selectedUser && selectedUser.id === prop.id}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedUser(prop)
              }
            }}
          />
        </div>
      ),
    }
  }

  const onHealthCardFileUpload = () => {
    healthCardFileInput.current.click()
  }

  const onHealthCardFileChange = (e) => {
    setHealthCardFile(e.target.files[0])
    setHealthCardFileId(null)
  }

  return (
    <Modal show={props.show} onHide={() => props.onHide()} dialogClassName={props.mode === "search" ? "wide" : ""}>
      <Modal.Header closeButton>
        <Modal.Title>{props.mode === "search" ? "Select User Account" : "Patient's Information"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          {!props.mode ||
            (props.mode === "new" && (
              <div className="flex flex-col gap-10">
                <Card
                  content={
                    <div
                      className="grid"
                      style={{
                        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
                        gridColumnGap: "16px",
                      }}>
                      <FormGroup>
                        <ControlLabel>
                          First Name: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          type="text"
                          maxLength="32"
                          name="firstName"
                          value={firstName}
                          onChange={(event) => {
                            setFirstName(event.target.value.replace(/[^a-z ]/gi, ""))
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          Last Name: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          type="text"
                          maxLength="32"
                          name="lastName"
                          value={lastName}
                          onChange={(event) => {
                            setLastName(event.target.value.replace(/[^a-z ]/gi, ""))
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <ControlLabel>
                          DATE OF BIRTH: <span className="star">*</span>
                        </ControlLabel>
                        <div>
                          <ReactDatetime
                            dateFormat={dateFormat}
                            value={dob}
                            closeOnSelect={true}
                            inputProps={{
                              required: true,
                              placeholder: "Date of Birth",
                            }}
                            timeFormat={false}
                            isValidDate={isValidDob}
                            onChange={handleBirthDayChange}
                          />
                        </div>
                      </FormGroup>

                      <div className="form-group phone-input">
                        <ControlLabel>
                          Phone Number: <span className="star">*</span>
                        </ControlLabel>
                        <PhoneInput
                          maxLength="16"
                          max="10"
                          defaultCountry="CA"
                          extension="true"
                          value={phone}
                          onChange={(value) => {
                            setPhone(value)
                            if (value && !isPossiblePhoneNumber(value)) {
                              setPhoneError(<small className="text-danger">A valid Canadian or US phone number is required.</small>)
                            } else {
                              setPhoneError(null)
                            }
                          }}
                          required
                        />
                        {phoneError}
                      </div>

                      <FormGroup style={{ gridColumn: "1 / span 2" }}>
                        <ControlLabel>
                          Email address: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          maxLength="100"
                          type="text"
                          name="email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          onBlur={(event) => handleEmailChange(event)}
                          required
                        />
                        {emailError}
                      </FormGroup>
                      {/*
                      <FormGroup>
                        <ControlLabel>
                          Gender: <span className="star">*</span>
                        </ControlLabel>
                        <Select
                          name="gender-select"
                          isClearable={false}
                          className="react-select react-select-icon"
                          isSearchable={true}
                          placeholder="Select Gender"
                          noOptionsMessage={(inp) => "No Genders Loaded"}
                          isMulti={false}
                          value={gender}
                          onChange={(opt) => {
                            setGender(opt);
                          }}
                          options={genders.map((s) => {
                            return { label: s.value, value: s.key };
                          })}
                          formatGroupLabel={(data) => (
                            <div className="flex items-center text-theme font-semibold">
                              {data.label}
                            </div>
                          )}
                        />
                      </FormGroup>
                      */}
                    </div>
                  }
                />

                {/* <Card
                  content={
                    <div
                      className="grid"
                      style={{
                        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
                        gridColumnGap: "16px",
                      }}>
                      <FormGroup>
                        <ControlLabel>
                          Health Card Number: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          type="text"
                          maxLength="50"
                          name="hcNumber"
                          value={hcNumber}
                          placeholder="Health Card Number"
                          onChange={(event) => {
                            setHcNumber(event.target.value)
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <ControlLabel>Expiry Date:</ControlLabel>
                        <div>
                          <ReactDatetime
                            dateFormat={dateFormat}
                            value={hcExpiryDate}
                            closeOnSelect={true}
                            inputProps={{
                              required: true,
                              placeholder: "Card Expiry Date (optional)",
                            }}
                            timeFormat={false}
                            isValidDate={isValidExpiryDate}
                            onChange={(e) => {
                              setHcExpiryDate(moment(e).toDate())
                            }}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <ControlLabel>
                          Province: <span className="star">*</span>
                        </ControlLabel>
                        <Select
                          name="province-select"
                          isClearable={false}
                          className="react-select react-select-icon"
                          isSearchable={true}
                          placeholder="Select Province"
                          noOptionsMessage={(inp) => "No Provinces Loaded"}
                          isMulti={false}
                          value={hcProvince}
                          onChange={(opt) => {
                            setHcProvince(opt)
                          }}
                          options={provinces}
                          formatGroupLabel={(data) => <div className="flex items-center text-theme font-semibold">{data.label}</div>}
                        />
                      </FormGroup>

                      <FormGroup>
                        <ControlLabel>
                          Health Card Picture: <span className="star">*</span>
                        </ControlLabel>
                        <div className="flex items-center">
                          <input
                            accept="image/png, image/jpeg"
                            ref={healthCardFileInput}
                            id="file-upload"
                            style={{ display: "none" }}
                            type="file"
                            onChange={onHealthCardFileChange}
                          />

                          {!healthCardFile && !healthCardFileId && (
                            <div className="flex items-center gap-6 w-full">
                              {!healthCardFile && !healthCardFileId && <div className="text-xs sub-text">Upload JPEG or PNG file</div>}
                              <Button fill bsStyle="danger" style={{ marginLeft: "auto" }} onClick={onHealthCardFileUpload}>
                                <Svg name="upload" className="w-4 h-4" />
                                <span>Upload</span>
                              </Button>
                            </div>
                          )}
                        </div>

                        {(healthCardFile || healthCardFileId) && (
                          <div className="flex items-center w-full">
                            <div
                              className="text-semibold main-text"
                              style={{
                                maxWidth: "75%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxHeight: "18px",
                              }}>
                              {healthCardFile ? healthCardFile.name : "Picture Attached"}
                            </div>
                            <Button
                              fill
                              bsStyle="danger"
                              style={{ marginLeft: "auto" }}
                              onClick={() => {
                                setHealthCardFile(null)
                                setHealthCardFileId(null)
                              }}>
                              <Svg name="trash" className="w-4 h-4" />
                            </Button>
                          </div>
                        )}
                      </FormGroup>
                    </div>
                  }
                /> */}
                {false && (
                  <Card
                    content={
                      <div
                        className="grid"
                        style={{
                          gridTemplateColumns: "repeat(2,minmax(0,1fr))",
                          gridColumnGap: "16px",
                        }}>
                        <FormGroup>
                          <ControlLabel>
                            Insurance Provider: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            maxLength="100"
                            name="insuranceProvider"
                            value={insuranceProvider}
                            onChange={(event) => {
                              setInsuranceProvider(event.target.value)
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Insurance Policy Number: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            maxLength="100"
                            name="insuranceNumber"
                            value={insuranceNumber}
                            onChange={(event) => {
                              setInsuranceNumber(event.target.value)
                            }}
                          />
                        </FormGroup>
                      </div>
                    }
                  />
                )}

                <Card
                  content={
                    <div className="flex flex-col">
                      <FormGroup>
                        <ControlLabel>Issues and Allergies:</ControlLabel>
                        <FormControl
                          type="text"
                          maxLength="500"
                          name="insuranceProvider"
                          value={allergies}
                          onChange={(event) => {
                            setAllergies(event.target.value)
                          }}
                        />
                      </FormGroup>
                    </div>
                  }
                />
              </div>
            ))}

          {props.mode === "search" && (
            <Card
              content={
                <div className="flex flex-col gap-16">
                  <ReactTable
                    loading={loading}
                    loadingText="Loading accounts..."
                    noDataText={error || "No accounts found..."}
                    data={data}
                    onFetchData={debounce(fetchData, 800)}
                    defaultFilterMethod={bypassFilter}
                    filterable
                    columns={[
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        minWidth: 40,
                        maxWidth: 40,
                      },
                      {
                        Header: "First Name",
                        accessor: "firstName",
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: "Last Name",
                        accessor: "lastName",
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: "Address",
                        accessor: "address",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        sortable: false,
                        filterable: false,
                        maxWidth: 90,
                      },
                    ]}
                    defaultPageSize={pageSize}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <Button
                      className="pagination-btn left btn-fill"
                      disabled={!previousPageToken || loading}
                      onClick={() => getUsers(previousPageToken, true)}
                      bsStyle="default"
                      fill>
                      <Svg name="chevron-left" className="w-6 h-6" />
                    </Button>
                    <Button
                      className="pagination-btn right btn-fill"
                      disabled={!nextPageToken || loading}
                      onClick={() => getUsers(nextPageToken, false)}
                      bsStyle="default"
                      fill>
                      <Svg name="chevron-right" className="w-6 h-6" />
                    </Button>
                  </div>
                </div>
              }
            />
          )}

          <div className="flex items-center gap-10" style={{ marginLeft: "auto", marginTop: "16px" }}>
            <Button bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Cancel
            </Button>

            <Button
              bsStyle="danger"
              fill
              onClick={() => onCreateAccount()}
              disabled={
                (props.mode === "search" && !selectedUser) ||
                ((props.mode === "new" || !props.mode) && (!firstName || !lastName || !dob || !email || !phone || phoneError || emailError))
                //|| !hcNumber ||
                // !hcProvince ||
                // (!healthCardFile && !healthCardFileId)
              }>
              {props.mode === "search" ? "Select User" : props.person ? "Save Patient" : "Add Patient"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddPatientModal
