import React, { useState, useEffect } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import moment from "moment";
import Datetime from "react-datetime";
import Select from "react-select";
import Swal from "sweetalert2";
import Switch from "react-switch";
import ReactTable from "react-table";
import axios from "axios";
import NumberFormat from "react-number-format";
import { parseError } from "api/common";
import Svg from "components/Svg/Svg";
import { dateTimeFormat } from "api/common";
import { dateFormat } from "api/common";

const EditPromocode = (props) => {
  const [codeId, setCodeId] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [value, setValue] = useState(1);
  const [expireAt, setExpiration] = useState(null);
  const [type, setType] = useState({ value: "fixed", label: "Fixed" });
  const [maxRedemptions, setRedemptions] = useState(1);
  const [loading, setLoading] = useState(false);
  const [types] = useState([
    { label: "Fixed", value: "fixed" },
    { label: "Percentage", value: "percentage" },
  ]);
  const [multiRedemptionsEnabled, setMultiRedemptionsEnabled] = useState(false);
  const [zones, setZones] = useState([]);
  const [services, setServices] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(10);

  const disablePastDt = (current) => {
    return current.isAfter(moment());
  };

  useEffect(() => {
    const id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    setCodeId(id);
  }, []);

  useEffect(() => {
    if (codeId) {
      getDiscountCode(codeId);
    }
  }, [codeId]);

  const getDiscountCode = (id) => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/discounts/${encodeURIComponent(id)}`)
      .then((res) => {
        setName(res.data.name);
        setCode(res.data.code);
        setType(types.find((t) => t.value === res.data.type));
        setExpiration(
          moment(
            `${res.data.expireAt.month}/${res.data.expireAt.day}/${res.data.expireAt.year}`,
            "M/D/YYYY"
          ).toDate()
        );
        setRedemptions(res.data.maxRedemptions || 1);

        if (res.data.type == "fixed") {
          setValue(parseFloat(res.data.value / 100));
        } else if (res.data.type == "percentage") {
          setValue(parseInt(res.data.value));
        }

        setMultiRedemptionsEnabled(res.data.allowMultiAccountRedemptions);
        loadRelatedData(res.data);
        getDiscountHistory();
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Promo Code Error", error, "error");
      });
  };

  const loadRelatedData = (codeData) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/zones`)
      .then((res) => {
        const zonesList = res.data.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));
        setZoneOptions(zonesList);

        let currentZones = zonesList.filter((s) =>
          codeData.zones.some((z) => z.replace("ZN#", "") === s.value)
        );
        setZones(currentZones);
      })
      .catch((err) => {
        parseError(err);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/services`)
      .then((res) => {
        const servicesList = res.data.map((svc) => ({
          value: svc.id,
          label: svc.title,
        }));
        setServiceOptions(servicesList);

        let currentServices = servicesList.filter((s) =>
          codeData.services.some((z) => z.replace("SE#", "") === s.value)
        );
        setServices(currentServices);
      })
      .catch((err) => {
        parseError(err);
      });
  };

  const getDiscountHistory = (pageToken = null, isBackward = null) => {
    setLoading(true);
    setDataHistory([]);

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/discounts/${codeId}/history?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward ? "true" : "false"}`
      )
      .then((res) => {
        setLoading(false);
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);

        setDataHistory(
          res.data.items.map((prop) => {
            return {
              id: prop.id,
              usedAt: formatDate(prop.usedAt),
              patientName: `${prop.patient.firstName} ${prop.patient.lastName}`,
              email: prop.patient.email,
              appointment: (
                <Link to={`/admin/appointments/${prop.appointmentId}`} title="View Appointment">
                  {prop.appointmentId}
                </Link>
              ),
              amount: `$${new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                signDisplay: "never",
              }).format(prop.amount != 0 ? prop.amount / 100.0 : 0)}`,
            };
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Promo History Error", error, "error");
      });
  };

  const changeDate = (event) => {
    setExpiration(event.toDate());
  };

  const updateDiscount = () => {
    setLoading(true);

    let priceValue = value;
    if (type.value === "fixed") {
      priceValue = priceValue * 100;
    }

    const data = {
      name: name,
      code: code,
      value: priceValue,
      expireAt: {
        year: expireAt.getFullYear(),
        month: expireAt.getMonth() + 1,
        day: expireAt.getDate(),
      },
      type: type.value,
      maxRedemptions: maxRedemptions,
      zones: zones.map((s) => s.value),
      services: services.map((s) => s.value),
      allowMultiAccountRedemptions: multiRedemptionsEnabled,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/discounts/${codeId}`, data)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          title: "Promo Code Has Been Updated",
          icon: "success",
          showCancelButton: false,
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Promo Code Error", error, "error");
      });
  };

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--";
    }
    let utcIsoString = `${inDate.year}-${inDate.month.toString().padStart(2, "0")}-${inDate.day
      .toString()
      .padStart(2, "0")}T${inDate.hour.toString().padStart(2, "0")}:${inDate.minute
      .toString()
      .padStart(2, "0")}:00.000Z`;

    let utcDate = moment(utcIsoString);
    return utcDate.format(dateTimeFormat);
  };

  return (
    <div className="main-content promo">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "12px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Back To List</span>
      </Button>
      <div className="flex flex-col">
        <Card
          title="Promo Code Details"
          content={
            <div className="flex flex-col gap-12">
              <div className="grid grid-2col gap-12">
                <div className="flex items-start gap-16" style={{ gridColumn: "1 / span 2" }}>
                  <FormGroup className="w-full">
                    <ControlLabel>Promo Name (Internal):</ControlLabel>
                    <FormControl
                      disabled={loading}
                      required
                      type="text"
                      name="name"
                      placeholder="Name that only visible to admins"
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FormGroup>

                  <div className="flex items-center gap-10 w-full">
                    <FormGroup>
                      <ControlLabel>Code (for customer):</ControlLabel>
                      <FormControl
                        disabled={true}
                        required
                        type="text"
                        name="code"
                        placeholder="Code"
                        value={code}
                      />
                    </FormGroup>
                  </div>
                </div>

                <FormGroup>
                  <ControlLabel>
                    Type: <span className="star">*</span>
                  </ControlLabel>
                  <Select
                    isDisabled={loading}
                    name="type-select"
                    isClearable={false}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select Type"
                    isMulti={false}
                    value={type}
                    onChange={(opt) => {
                      setType(opt);
                      if (opt.value === "percentage") {
                        setValue(parseInt(value));
                      }
                    }}
                    options={types}
                    formatGroupLabel={(data) => (
                      <div className="flex items-center text-theme font-semibold">{data.label}</div>
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Value in {type.value === "percentage" ? "%" : "$"}:</ControlLabel>
                  <div className="flex items-center">
                    <NumberFormat
                      value={value}
                      defaultValue={0}
                      decimalScale={type.value === "percentage" ? 0 : 2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      suffix={type.value === "percentage" ? "%" : ""}
                      prefix={type.value === "fixed" ? "$" : ""}
                      className="form-control"
                      inputMode="numeric"
                      placeholder="Discount Value"
                      isAllowed={({ floatValue }) => {
                        return (
                          floatValue == undefined ||
                          floatValue <= (type.value === "percentage" ? 100 : 9999999.99)
                        );
                      }}
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setValue(
                          value
                            ? type.value === "percentage"
                              ? parseInt(value)
                              : parseFloat(value)
                            : 0
                        );
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Maximum Redemptions:</ControlLabel>
                  <FormControl
                    required
                    disabled={loading}
                    type="number"
                    name="maxRedemptions"
                    placeholder="Maximum Redemptions"
                    min={0}
                    max={999999999}
                    value={maxRedemptions}
                    onChange={(event) => {
                      setRedemptions(parseInt(event.target.value));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    Expiry: <span className="star">*</span>
                  </ControlLabel>
                  <Datetime
                    dateFormat={dateFormat}
                    disabled={loading}
                    timeFormat={false}
                    inputProps={{ placeholder: "Select Expiration Date" }}
                    isValidDate={disablePastDt}
                    value={expireAt}
                    onChange={changeDate}
                    closeOnSelect={true}
                  />
                </FormGroup>

                <FormGroup className="flex flex-col">
                  <ControlLabel>Service Locations (Zones):</ControlLabel>
                  <Select
                    name="zone-tech-select"
                    isClearable={true}
                    isDisabled={loading}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select Service Locations"
                    noOptionsMessage={() => "No Service Locations"}
                    isMulti={true}
                    value={zones}
                    onChange={(opt) => {
                      setZones(opt);
                    }}
                    options={zoneOptions}
                  />
                  <span
                    className="text-xxs sub-text"
                    style={{ marginTop: "6px", marginLeft: "4px" }}
                  >
                    If any service locations are selected, then this promo code can only be applied
                    to the appointments with the address within that serviceable zone.
                  </span>
                </FormGroup>

                <FormGroup className="flex flex-col">
                  <ControlLabel>Services:</ControlLabel>
                  <Select
                    name="services-tech-select"
                    isClearable={true}
                    isDisabled={loading}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select Services"
                    noOptionsMessage={() => "No Services"}
                    isMulti={true}
                    value={services}
                    onChange={(opt) => {
                      setServices(opt);
                    }}
                    options={serviceOptions}
                  />
                  <span
                    className="text-xxs sub-text"
                    style={{ marginTop: "6px", marginLeft: "4px" }}
                  >
                    If services are selected, then this promo code can only be applied to the
                    appointments with these services.
                  </span>
                </FormGroup>

                <div className="block-group">
                  <div className="switch">
                    <FormGroup>
                      <Switch
                        disabled={loading}
                        onChange={(checked) => setMultiRedemptionsEnabled(checked)}
                        checked={multiRedemptionsEnabled}
                        offColor="#E6E6E6"
                        onColor="#aac1e6"
                        onHandleColor="#189A62"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={40}
                      />
                      <ControlLabel>Multiple Redemptions Per Account</ControlLabel>
                    </FormGroup>
                  </div>
                  <span className="text-xxs sub-text">
                    If this option is enabled, then the customers will be able to use this promo
                    code multiple times. Otherwise promo code usage will be limited to one time per
                    the customer's account.
                  </span>
                </div>
              </div>

              <Button
                loading={loading}
                style={{ marginLeft: "auto" }}
                disabled={
                  loading ||
                  !name ||
                  !expireAt ||
                  !value ||
                  !maxRedemptions ||
                  !type ||
                  !type.value ||
                  !code
                }
                bsStyle="danger"
                fill
                onClick={() => updateDiscount()}
              >
                Save Changes
              </Button>
            </div>
          }
        />

        <Card
          title="Redemption History"
          content={
            <div className="flex flex-col">
              <ReactTable
                noDataText={"No redemptions found..."}
                data={dataHistory}
                filterable
                columns={[
                  {
                    Header: "Redeemed By",
                    accessor: "patientName",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                    sortable: false,
                    filterable: false,
                    style: { whiteSpace: "unset" },
                  },
                  {
                    Header: "Redeemed On",
                    accessor: "usedAt",
                    sortable: false,
                    filterable: false,
                    maxWidth: 150,
                  },
                  {
                    Header: "Discount",
                    accessor: "amount",
                    sortable: false,
                    filterable: false,
                    maxWidth: 110,
                  },
                  {
                    Header: "Appointment",
                    accessor: "appointment",
                    sortable: false,
                    filterable: false,
                    maxWidth: 130,
                  },
                ]}
                defaultPageSize={pageSize}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
              <div className="flex items-center" style={{ marginLeft: "auto" }}>
                <Button
                  className="pagination-btn left btn-fill"
                  disabled={!previousPageToken || loading}
                  onClick={() => getDiscountHistory(previousPageToken, true)}
                  bsStyle="default"
                  fill
                >
                  <Svg name="chevron-left" className="w-6 h-6" />
                </Button>
                <Button
                  className="pagination-btn right btn-fill"
                  disabled={!nextPageToken || loading}
                  onClick={() => getDiscountHistory(nextPageToken, false)}
                  bsStyle="default"
                  fill
                >
                  <Svg name="chevron-right" className="w-6 h-6" />
                </Button>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default EditPromocode;
