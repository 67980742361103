import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactTable from "react-table";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Svg from "components/Svg/Svg.jsx";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Rating from "react-rating";
import { parseError, getAppointmentStatusName } from "api/common.js";
import { debounce } from "debounce";
import { dateTimeFormat } from "api/common";

const UserReviews = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(props.pageSize || 12);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, []);

  const getReviews = (pageToken = null, isBackward = null) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);
    setData([]);

    let baseUrl = ``;
    if (props.techId) {
      baseUrl = `${process.env.REACT_APP_API_URL}/technicians/${encodeURIComponent(
        props.techId
      )}/reviews`;
    } else if (props.patientId) {
      baseUrl = `${process.env.REACT_APP_API_URL}/account/${encodeURIComponent(
        props.patientId
      )}/reviews`;
    }

    if (!baseUrl) {
      return;
    }

    axios
      .get(
        `${baseUrl}?pageSize=${pageSize}&paginationToken=${pageToken || ""}&isBackward=${
          isBackward ? "true" : "false"
        }`
      )
      .then((res) => {
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);
        setData(mapItems(res.data.items));
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const fetchData = () => {
    getReviews();
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapReview(prop);
    });
  };

  const mapReview = (prop) => {
    return {
      id: prop.id,
      date: formatDate(prop.createdAt),
      rating: (
        <Rating
          style={{ marginLeft: "4px" }}
          initialRating={prop.rate}
          emptySymbol="fa fa-star-o theme-text"
          fullSymbol="fa fa-star theme-text"
          readonly
        />
      ),
      note: prop.note,
      reviewer: formatReviewer(prop.reviewer),
      actions: (
        <div className="flex items-center gap-6">
          <Button
            disabled={loading}
            onClick={() => deleteRating(prop.id)}
            title="Delete Review"
            bsStyle="danger"
            outline="true"
            style={{ padding: "7px 10px" }}
          >
            <Svg name="trash" className="w-4 h-4" />
          </Button>
        </div>
      ),
    };
  };

  const deleteRating = (key) => {
    let baseUrl = ``;
    if (props.techId) {
      baseUrl = `${process.env.REACT_APP_API_URL}/technicians/${encodeURIComponent(
        props.techId
      )}/reviews/${encodeURIComponent(key)}`;
    } else if (props.patientId) {
      baseUrl = `${process.env.REACT_APP_API_URL}/account/${encodeURIComponent(
        props.patientId
      )}/reviews/${encodeURIComponent(key)}`;
    }

    if (!baseUrl) {
      return;
    }

    Swal.fire({
      title: `Are you sure you want to delete this review?`,
      text: "Deleting this review will result in re-calculating the avarage rating for this account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#189A62",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Review",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        axios
          .delete(baseUrl)
          .then((res) => {
            setLoading(false);
            getReviews();

            if (props.onUpdate) {
              props.onUpdate(res.data);
            }

            Swal.fire({
              title: "Review Deleted",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            setLoading(false);
            let error = parseError(err);
            Swal.fire("Delete Error", error, "error");
          });
      }
    });
  };

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--";
    }

    let dateString = `${inDate.day.toString().padStart(2, "0")}/${inDate.month
      .toString()
      .padStart(2, "0")}/${inDate.year} ${inDate.hour.toString().padStart(2, "0")}:${inDate.minute
      .toString()
      .padStart(2, "0")}`;

    const localDate = moment.utc(dateString, "DD/MM/YYYY HH:mm").local();

    return localDate.format(dateTimeFormat);
  };

  const formatReviewer = (person) => {
    if (props.techId) {
      return (
        <Link to={`/admin/users/${encodeURIComponent(person.id)}`} title="View Account">
          {person.firstName} {person.lastName}
        </Link>
      );
    } else {
      return (
        <Link to={`/admin/lab-tech-details/${encodeURIComponent(person.id)}`} title="View Account">
          {person.firstName} {person.lastName}
        </Link>
      );
    }
  };

  return (
    <div className="flex flex-col gap-16">
      <ReactTable
        loading={loading}
        loadingText="Loading reviews..."
        noDataText={error || "No reviews found..."}
        data={data}
        onFetchData={debounce(fetchData, 800)}
        columns={[
          {
            Header: "Date",
            accessor: "date",
            sortable: false,
            maxWidth: 160,
          },
          {
            Header: "Rating",
            accessor: "rating",
            sortable: false,
            maxWidth: 130,
          },
          {
            Header: "Reviewer",
            accessor: "reviewer",
            sortable: false,
            maxWidth: 160,
          },
          {
            Header: "Review",
            accessor: "note",
            sortable: false,
            style: { textOverflow: "clip", whiteSpace: "pre-line" },
          },
          {
            Header: "",
            accessor: "actions",
            sortable: false,
            filterable: false,
            maxWidth: 60,
            resizable: false,
          },
        ]}
        defaultPageSize={pageSize}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
      <div className="flex items-center" style={{ marginLeft: "auto" }}>
        <Button
          className="pagination-btn left btn-fill"
          disabled={!previousPageToken || loading}
          onClick={() => getReviews(previousPageToken, true)}
          bsStyle="default"
          fill
        >
          <Svg name="chevron-left" className="w-6 h-6" />
        </Button>
        <Button
          className="pagination-btn right btn-fill"
          disabled={!nextPageToken || loading}
          onClick={() => getReviews(nextPageToken, false)}
          bsStyle="default"
          fill
        >
          <Svg name="chevron-right" className="w-6 h-6" />
        </Button>
      </div>
    </div>
  );
};

export default UserReviews;
