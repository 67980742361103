import React, { useEffect, useState } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTable from "react-table";
import Swal from "sweetalert2";
import Svg from "components/Svg/Svg.jsx";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import { parseError } from "api/common.js";

const ServiceCentresListView = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [centresList, setCentresList] = useState([]);
  const [pageSize, setPageSize] = useState(15);

  useEffect(() => {
    getServiceCentres();
  }, []);

  const getServiceCentres = () => {
    setLoading(true);
    setError(null);
    setCentresList([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/service-centres`)
      .then((res) => {
        if (res.data.items.length > pageSize) {
          setPageSize(res.data.items.length);
        }
        setLoading(false);
        setCentresList(res.data.items);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const deleteServiceCentre = async (centre) => {
    Swal.fire({
      title: "",
      text: `Are you sure you want to delete the '${centre.name}' Service Centre?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#189A62",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Service Centre",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/service-centres/${centre.id}`)
          .then((res) => {
            getServiceCentres();

            Swal.fire({
              title: `Service Centre '${centre.name}' has been successfully deleted.`,
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            setLoading(false);
            Swal.fire({
              title: "Delete Error",
              text: error,
              icon: "error",
              showCancelButton: false,
            });
          });
      }
    });
  };

  return (
    <div className="main-content flex flex-col" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center">
            <div className="title">Service Centres</div>
            <Button
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
              onClick={() => {
                props.history.push("/admin/service-centres/new");
              }}
            >
              <Svg name="plus" className="w-4 h-4" />
              New Service Centre
            </Button>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <ReactTable
              loading={loading}
              loadingText="Loading service centres..."
              noDataText={error ? error : loading ? "" : "No service centres found..."}
              data={centresList.map((prop) => {
                return {
                  id: prop.id,
                  name: prop.name,
                  address: `${prop.address.city}, ${prop.address.province}`,
                  actions: (
                    <div className="flex items-center gap-6">
                      <Button
                        title="View/Edit Details"
                        bsStyle="danger"
                        outline="true"
                        style={{ padding: "7px 10px" }}
                        onClick={() => props.history.push(`/admin/service-centres/${prop.id}`)}
                      >
                        <Svg name="edit" className="w-4 h-4" />
                      </Button>
                      <Button
                        onClick={() => deleteServiceCentre(prop)}
                        title="Delete Service Centre"
                        bsStyle="danger"
                        outline="true"
                        style={{ padding: "7px 10px" }}
                      >
                        <Svg name="trash" className="w-4 h-4" />
                      </Button>
                    </div>
                  ),
                };
              })}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                },
                {
                  Header: "Location",
                  accessor: "address",
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  maxWidth: 100,
                  minWidth: 100,
                },
              ]}
              defaultPageSize={pageSize}
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </div>
        }
      />
    </div>
  );
};

export default ServiceCentresListView;
