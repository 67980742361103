import React, { useEffect, useState } from "react";
import moment from "moment";
import Chart from "react-google-charts";
import { TimelineSVG } from "../../../components/Timeline/Timeline";

const AppointmentTimeline = (props) => {
  const [chartTasks, setChartTasks] = useState([]);
  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    if (!props.appointment) {
      setTimelineData([]);
      setChartTasks([]);
      return;
    }

    prepareChartData(props.appointment);

    let timelineEvents = [];

    const bookDate = parseDate(props.appointment.bookedAt);
    const isCancelled = props.appointment.status === "cancelled";
    let cancelHappened = false;
    const cancelledDate = isCancelled ? parseDate(props.appointment.cancelledAt) : null;
    const cancelEvent = {
      stepsCompleted: 1,
      date: cancelledDate,
      hasIncident: false,
      label: "Cancelled",
      steps: 1,
      breakPoint: true,
    };

    timelineEvents.push({
      stepsCompleted: 1,
      date: bookDate,
      hasIncident: false,
      label: "Booked",
      steps: 1,
    });

    if (isCancelled && !props.appointment.navigationStartedAt) {
      timelineEvents.push(cancelEvent);
      cancelHappened = true;
    }

    if (!cancelHappened) {
      timelineEvents.push({
        stepsCompleted: props.appointment.navigationStartedAt ? 2 : 1,
        date: props.appointment.navigationStartedAt
          ? parseDate(props.appointment.navigationStartedAt)
          : null,
        hasIncident: false,
        label: "In Transit",
        steps: 2,
      });
    }

    if (isCancelled && props.appointment.navigationStartedAt && !props.appointment.startedAt) {
      timelineEvents.push(cancelEvent);
      cancelHappened = true;
    }

    if (!cancelHappened) {
      timelineEvents.push({
        stepsCompleted: props.appointment.startedAt
          ? 2
          : props.appointment.navigationStartedAt
          ? 1
          : null,
        date: props.appointment.startedAt ? parseDate(props.appointment.startedAt) : null,
        hasIncident: false,
        label: "Started",
        steps: 2,
      });
    }

    if (isCancelled && props.appointment.startedAt && !props.appointment.endedAt) {
      timelineEvents.push(cancelEvent);
      cancelHappened = true;
    }

    if (!cancelHappened) {
      timelineEvents.push({
        stepsCompleted: props.appointment.endedAt ? 2 : props.appointment.startedAt ? 1 : null,
        date: props.appointment.endedAt ? parseDate(props.appointment.endedAt) : null,
        hasIncident: false,
        label: "Completed",
        steps: 2,
      });
    }

    if (!cancelHappened) {
      timelineEvents.push({
        stepsCompleted: props.appointment.samplesDroppedOffAt
          ? 2
          : props.appointment.endedAt
          ? 1
          : null,
        date: props.appointment.samplesDroppedOffAt
          ? parseDate(props.appointment.samplesDroppedOffAt)
          : null,
        hasIncident: false,
        label: "Dropped Off",
        steps: 2,
        success: true,
      });
    }

    timelineEvents.push({
      stepsCompleted: null,
      hasIncident: false,
      label: "",
      finish: true,
      steps: 1,
    });

    setTimelineData(timelineEvents);
  }, [props.appointment]);

  const parseDate = (inDate) => {
    return moment(
      `${inDate.month}/${inDate.day}/${inDate.year} ${inDate.hour}:${inDate.minute}:${inDate.second}`,
      "M/D/YYYY H:m:s"
    ).toDate();
  };

  const prepareChartData = (app) => {
    setChartTasks([]);

    let tasks = [
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ],
    ];

    if (app.type === "asap" && app.acceptedAt) {
      const bookedAt = parseDate(app.bookedAt);
      const acceptedAt = parseDate(app.acceptedAt);
      const bookedToAccepted = moment(acceptedAt).diff(bookedAt, "seconds", true);

      tasks.push([
        "booked",
        `Booked ➜ Accepted (${secondsToDhms(bookedToAccepted || 1)})`,
        null,
        null,
        bookedToAccepted > 0 ? bookedToAccepted * 1000 : 1000,
        100,
        null,
      ]);
    }

    if (app.startedAt) {
      const acceptedAt = parseDate(
        app.type === "asap" && app.acceptedAt ? app.acceptedAt : app.navigationStartedAt,
        true
      );
      const startedAt = parseDate(app.startedAt);
      const acceptedToStarted =
        acceptedAt !== startedAt ? moment(startedAt).diff(acceptedAt, "seconds") : 1;

      tasks.push([
        "accept",
        `Accepted ➜ Started (${secondsToDhms(acceptedToStarted || 1)})`,
        null,
        null,
        acceptedToStarted > 0 ? acceptedToStarted * 1000 : 1000,
        100,
        app.type === "asap" && app.acceptedAt ? "booked" : null,
      ]);
    }

    if (app.endedAt) {
      const endedAt = parseDate(app.endedAt);
      const startedAt = parseDate(app.startedAt);
      const startedToFinished =
        endedAt !== startedAt ? moment(endedAt).diff(startedAt, "seconds") : 1;

      tasks.push([
        "start",
        `Started ➜ Completed (${secondsToDhms(startedToFinished || 1)})`,
        null,
        null,
        startedToFinished > 0 ? startedToFinished * 1000 : 1000,
        100,
        "accept",
      ]);
    }

    if (app.samplesDroppedOffAt) {
      const endedAt = parseDate(app.endedAt);
      const samplesDroppedOffAt = parseDate(app.samplesDroppedOffAt);
      const finishToDropOff = moment(samplesDroppedOffAt).diff(endedAt, "seconds");

      tasks.push([
        "finish",
        `Completed ➜ Dropped Off (${secondsToDhms(finishToDropOff || 1)})`,
        null,
        null,
        finishToDropOff > 0 ? finishToDropOff * 1000 : 1000,
        100,
        "start",
      ]);
    }

    if (tasks.length > 1) {
      setChartTasks(tasks);
    }
  };

  const secondsToDhms = (secs, long = false) => {
    if (isNaN(secs)) {
      return "--";
    }

    const d = Math.floor(secs / (3600 * 24));
    const h = Math.floor((secs / 3600) % 24);
    const m = Math.floor((secs / 60) % 60);
    const s = Math.floor(secs % 60);
    const dDisplay = d > 0 ? d + (long ? (d === 1 ? " day, " : " days, ") : "d ") : "";
    const hDisplay = h > 0 ? h + (long ? (h === 1 ? " hour, " : " hours, ") : "h ") : "";
    const mDisplay = m > 0 ? m + (long ? (m === 1 ? " minute, " : " minutes, ") : "m ") : "";
    const sDisplay = s > 0 ? s + (long ? (s === 1 ? " second" : " seconds") : "s") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  };

  return (
    <div className="flex flex-col gap-24 w-full">
      <div className="flex flex-col gap-6">
        <div className="main-text text-xs text-semibold">PROGRESS</div>
        {timelineData.length > 0 && <TimelineSVG milestones={timelineData} />}
      </div>

      <div className="separator horizontal"></div>

      <div className="flex flex-col gap-6">
        <div className="main-text text-xs text-semibold">DURATION</div>
        {props.appointment && props.appointment.type === "scheduled" && chartTasks.length > 0 && (
          <span className="sub-text text-xxs">
            The stage 'Accepted ➜ Started' reflects time from the moment when technicians starts
            navigation to the appointment's location and the moment when the appointment has
            started.
          </span>
        )}
        <div className="flex flex-col" id="scheduled-gannt">
          {chartTasks.length === 0 && (
            <span className="sub-text text-xs">
              No observed appointment steps have been completed...
            </span>
          )}
          {chartTasks.length > 0 && (
            <Chart
              legendToggle
              width={"100%"}
              height={`${(chartTasks.length > 0 ? chartTasks.length - 1 : 0) * 40}px`}
              chartType="Gantt"
              options={{
                legend: { position: "top", maxLines: 3 },
                gantt: {
                  barHeight: 24,
                  shadowEnabled: false,
                  barCornerRadius: 4,
                  criticalPathEnabled: false,
                  percentEnabled: false,
                  arrow: {
                    radius: 24,
                  },
                  labelStyle: {
                    fontName: "Inter",
                    fontSize: 13,
                  },
                  palette: [
                    {
                      color: "#189A62",
                      dark: "#002766",
                      light: "#99c0ff",
                    },
                  ],
                },
              }}
              loader={<div>Loading data...</div>}
              data={chartTasks}
              chartEvents={[
                {
                  eventName: "ready",
                  callback: ({ chartWrapper, google }) => {
                    var observer = new MutationObserver(function (nodes) {
                      Array.prototype.forEach.call(nodes, function (node) {
                        if (node.addedNodes.length > 0) {
                          Array.prototype.forEach.call(node.addedNodes, function (addedNode) {
                            if (
                              addedNode.tagName === "rect" &&
                              addedNode.getAttribute("fill") === "white"
                            ) {
                              addedNode.setAttribute("fill", "transparent");
                              addedNode.setAttribute("stroke", "transparent");
                              Array.prototype.forEach.call(
                                addedNode.parentNode.getElementsByTagName("text"),
                                function (label) {
                                  label.setAttribute("fill", "transparent");
                                }
                              );
                            }
                          });
                        }
                      });
                    });
                    observer.observe(document.getElementById("scheduled-gannt"), {
                      childList: true,
                      subtree: true,
                    });
                  },
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentTimeline;
