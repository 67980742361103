import React, { useEffect, useState } from "react"
import axios from "axios"
import MapGL, { Marker, NavigationControl } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "mapbox-gl/dist/mapbox-gl"
import Card from "components/Card/Card.jsx"
import Button from "components/CustomButton/CustomButton.jsx"
import moment from "moment"
import Svg from "../../../components/Svg/Svg"
import { dateTimeFormat, dateFormat, parseError, getAppointmentStatusName, useComponentSize, useWindowSize, getBoundsForPoints } from "api/common.js"
import "react-phone-number-input/style.css"
import "react-day-picker/lib/style.css"
import { Link } from "react-router-dom"
import Loader from "../../../views/Components/Loader/Loader.jsx"
import LocationMarker from "../../../assets/img/appointment-marker.png"
import CancelModal from "./CancelModal.jsx"
import RefundModal from "./RefundModal.jsx"
import RescheduleModal from "./RescheduleModal.jsx"
import ReassignModal from "./ReassignModal.jsx"
import CompleteModal from "./CompleteModal.jsx"
import FormPreviewModal from "./FormPreviewModal.jsx"
import QuestionnaireModal from "./QuestionnaireModal.jsx"
import ServiceReportPreviewModal from "./ServiceReportPreviewModal.jsx"
import HealthCardPreviewModal from "./HealthCardPreviewModal"
import NotesModal from "./NotesModal.jsx"
import ServiceReportsModal from "./ServiceReportsModal.jsx"
import AppointmentTimeline from "./AppointmentTimeline"
import MedicalNotesModal from "./MedicalNotesModal.jsx"
import { formatFullAddress } from "api/common"
import PlaceholderImage from "../../../assets/img/placeholder.svg"

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const AppointmentDetails = (props) => {
  const contentRef = React.useRef(null)
  const { width: contentWidth } = useComponentSize(contentRef)
  const { width: windowWidth } = useWindowSize()
  const [genders, setGenders] = useState([])
  const [corporateProfile, setCorporateProfile] = useState(null)
  const [appointmentError, setAppointmentError] = useState(null)
  const [appointmentData, setAppointmentData] = useState(null)
  const [paymentsList, setPaymentsList] = useState([])
  const [loading, setLoading] = useState(false)
  const [viewport, setViewport] = useState({
    latitude: 45.4994169,
    longitude: -73.6651472,
    zoom: 11,
    width: "100%",
    height: `400px`,
  })
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [refundModalOpen, setRefundModalOpen] = useState(false)
  const [completeModalOpen, setCompleteModalOpen] = useState(false)
  const [reassignModalOpen, setReassignModalOpen] = useState(false)
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false)
  const [formKey, setFormKey] = useState(null)
  const [formType, setFormType] = useState("requisition")
  const [healthCardKey, setHealthCardKey] = useState(null)
  const [reportKey, setReportKey] = useState(null)
  const [appointmentItems, setAppointmentItems] = useState([])
  const [notesModalOpen, setNotesModalOpen] = useState(false)
  const [reportsModalOpen, setReportsModalOpen] = useState(false)
  const [questionnaireKey, setQuestionnaireKey] = useState(undefined)
  const [medicalNotes, setMedicalNotes] = useState(undefined)

  useEffect(() => {
    // axios
    //   .get(`${process.env.REACT_APP_API_URL}/account/genders`)
    //   .then((res) => {
    //     setGenders(res.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    const appointmentId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    if (appointmentId) {
      loadAppointment(appointmentId)
    }
  }, [])

  useEffect(() => {
    if (appointmentData && appointmentData.corporateId) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/corporate/${encodeURIComponent(appointmentData.corporateId)}`)
        .then((res) => {
          setCorporateProfile(res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [appointmentData])

  useEffect(() => {
    let vw = viewport
    vw.width = `${contentWidth > 0 ? `${contentWidth}px` : "100%"}`
    setViewport(vw)
  }, [contentWidth, windowWidth])

  const loadAppointment = (appId) => {
    setAppointmentError(null)
    setAppointmentData(null)
    setLoading(true)

    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appId}`)
      .then((res) => {
        setLoading(false)
        setAppointmentData(res.data)
        getPayments(res.data.id)

        let vw = viewport
        vw.latitude = res.data.location.latitude
        vw.longitude = res.data.location.longitude
        vw.width = `${contentWidth > 0 ? `${contentWidth}px` : "100%"}`
        setViewport(vw)

        let points = [{ latitude: res.data.location.latitude, longitude: res.data.location.longitude }]

        try {
          let centre = getBoundsForPoints(points, 400)
          setViewport({
            latitude: centre.latitude,
            longitude: centre.longitude,
            zoom: (centre.zoom > 12 ? 12 : centre.zoom) || props.zoom,
            width: "100%",
            height: `400px`,
          })
        } catch (mErr) {
          console.log(mErr)
        }

        if (res.data.status === "completed" || res.data.status === "ended") {
          getItems(res.data.id)
        }
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setAppointmentError(error)
      })
  }

  const getItems = (appId) => {
    setAppointmentItems([])

    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appId}/items`)
      .then((res) => {
        setAppointmentItems(res.data || [])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getPayments = (appointmentId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appointmentId}/payments`)
      .then((res) => {
        setPaymentsList(res.data || [])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const formatStatus = (status) => {
    var badgeColor = "primary"
    switch (status) {
      case "technician_assigned":
      case "technician_in_transit":
      case "technician_at_location":
      case "pending":
        badgeColor = "info"
        break
      case "started":
      case "ended":
        badgeColor = "primary"
        break
      case "completed":
        badgeColor = "success"
        break
      case "cancelled":
        badgeColor = "warning"
        break
    }

    return <div className={`badge ${badgeColor}`}>{getAppointmentStatusName(status)}</div>
  }

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--"
    }

    let dateString = moment(`${inDate.day}/${inDate.month}/${inDate.year} ${inDate.hour}:${inDate.minute}`, "D/M/YYYY H:mm").format(dateTimeFormat)

    return dateString
  }

  const formatCancelReason = (reason) => {
    switch (reason) {
      case "requested_by_customer":
        return "Cancelled By Customer"
      case "cancelled_by_technician":
        return "Cancelled By Technician"
      case "due_failed_payment":
        return "Cancelled Due Failed Payment"
      case "cancelled_by_admin":
        return "Cancelled By Admin"
      case "internal_server_error":
        return "Unexpected System Issue"
      case "asap_no_one_accepted":
        return "No nurse has accepted ASAP request."
      case "asap_no_technician_was_found":
        return "No available nurses have been found."
    }
  }

  const getNormalDate = (input) => {
    return moment(`${input.month}/${input.day}/${input.year}`, "M/D/YYYY")
  }

  const getEpochDate = (epoch) => {
    var date = new Date(0)
    date.setUTCSeconds(epoch)

    return date
  }

  const formatPaymentStatus = (status) => {
    var badgeColor = "primary"
    var statusName = status

    switch (status) {
      case "pending":
        badgeColor = "info"
        statusName = "Pending"
        break
      case "authorized":
        badgeColor = "primary"
        statusName = "Pre-Authorized"
        break
      case "failed":
        badgeColor = "error"
        statusName = "Failed"
        break
      case "captured":
        badgeColor = "success"
        statusName = "Completed"
        break
      case "refunded":
        badgeColor = "warning"
        statusName = "Cancelled / Refunded"
        break
    }

    return <div className={`badge ${badgeColor}`}>{statusName}</div>
  }

  const formatPaymentType = (type) => {
    switch (type) {
      case "appointment":
        return "Appointment Payment"
      case "tip":
        return "Tip"
      case "cancellation_fee":
        return "Cancellation Fee"
    }
  }

  const getStripePaymentLink = (paymentId) => {
    let isProduction = process.env.NODE_ENV === "production"
    return `https://dashboard.stripe.com/${isProduction ? "" : "test/"}payments/${paymentId}`
  }

  const getAvailableToRefund = (payList) => {
    let availableAmount = 0

    payList
      .filter((s) => s.status === "captured")
      .forEach((p) => {
        availableAmount = availableAmount + (p.amount - p.refundedAmount)
      })

    return availableAmount
  }

  const onFormView = (key, type) => {
    setFormType(type)
    setFormKey(key)
  }

  const getAppointmentItemStatus = (list, item) => {
    if (list && list.length > 0 && item) {
      var aItem = list.find((s) => s.key === item.key)
      if (aItem) {
        let statusName = aItem.status === "dropped_off" ? "Dropped Off" : aItem.status === "completed" ? "Completed" : "Not Completed"

        return (
          <>
            {"/"}
            <div className={`badge ${aItem.status === "dropped_off" ? "success" : "primary"}`}>{statusName}</div>
          </>
        )
      }
    }
    return null
  }

  return (
    <div className="main-content create-appt flex gap-16 flex-col">
      <div className="flex items-cener">
        <Button
          simple
          icon
          style={{ padding: "0", marginBottom: "0px", marginRight: "auto" }}
          onClick={() => {
            props.history.go(-1)
          }}>
          <Svg name="chevron-left" className="w-6 h-6" />
          <span className="text-bold">Go Back</span>
        </Button>

        {appointmentData && (
          <div className="flex ml-auto gap-6" style={{ flexWrap: "wrap" }}>
            {(appointmentData.status === "pending" ||
              appointmentData.status === "technician_assigned" ||
              appointmentData.status === "technician_in_transit" ||
              appointmentData.status === "technician_at_location") && (
              <Button
                bsStyle="danger"
                fill
                disabled={loading}
                onClick={() => {
                  props.history.push(`/admin/appointment/edit?id=${appointmentData.id}`)
                }}>
                <Svg name="edit" className="w-4 h-4" />
                Edit
              </Button>
            )}
            {(appointmentData.status === "cancelled" || appointmentData.status === "ended" || appointmentData.status === "completed") && (
              <React.Fragment>
                <Button
                  disabled={getAvailableToRefund(paymentsList) <= 0 || paymentsList.length === 0 || loading}
                  bsStyle="danger"
                  fill
                  onClick={() => {
                    setRefundModalOpen(true)
                  }}>
                  <Svg name="undo" className="w-4 h-4" />
                  Refund
                </Button>

                <RefundModal
                  show={refundModalOpen}
                  appointmentId={appointmentData.id}
                  currency={appointmentData.cost.currency}
                  availableAmount={parseFloat(getAvailableToRefund(paymentsList))}
                  onHide={() => {
                    setRefundModalOpen(false)
                  }}
                  onConfirm={(list) => {
                    setPaymentsList(list)
                  }}
                />
              </React.Fragment>
            )}
            {appointmentData.status !== "pending" &&
              appointmentData.status !== "cancelled" &&
              appointmentData.status !== "ended" &&
              appointmentData.status !== "completed" && (
                <React.Fragment>
                  <Button
                    bsStyle="danger"
                    fill
                    disabled={loading}
                    onClick={() => {
                      setReassignModalOpen(true)
                    }}>
                    <Svg name="user-check" className="w-4 h-4" />
                    Reassign
                  </Button>

                  <ReassignModal
                    show={reassignModalOpen}
                    appointmentId={appointmentData.id}
                    appointmentDate={appointmentData.startTime}
                    onHide={() => {
                      setReassignModalOpen(false)
                    }}
                    onConfirm={(app) => {
                      setAppointmentData(app)
                    }}
                  />
                </React.Fragment>
              )}
            {appointmentData.type === "scheduled" && (appointmentData.status === "technician_assigned" || appointmentData.status === "pending") && (
              <React.Fragment>
                <Button
                  bsStyle="danger"
                  fill
                  disabled={loading}
                  onClick={() => {
                    setRescheduleModalOpen(true)
                  }}>
                  <Svg name="calendar" className="w-4 h-4" />
                  Reschedule
                </Button>

                <RescheduleModal
                  billingDisabled={false}
                  show={rescheduleModalOpen}
                  appointmentId={appointmentData.id}
                  appointment={appointmentData}
                  onHide={() => {
                    setRescheduleModalOpen(false)
                  }}
                  onConfirm={(app) => {
                    setAppointmentData(app)
                    getPayments(app.id)
                  }}
                />
              </React.Fragment>
            )}
            {appointmentData.status !== "cancelled" && appointmentData.status !== "ended" && appointmentData.status !== "completed" && (
              <React.Fragment>
                <Button
                  bsStyle="danger"
                  fill
                  disabled={loading}
                  onClick={() => {
                    setCancelModalOpen(true)
                  }}>
                  <Svg name="close" className="w-4 h-4" />
                  Cancel
                </Button>

                <CancelModal
                  show={cancelModalOpen}
                  appointmentId={appointmentData.id}
                  onHide={() => {
                    setCancelModalOpen(false)
                  }}
                  onConfirm={(app) => {
                    setAppointmentData(app)
                    getPayments(app.id)
                  }}
                />
              </React.Fragment>
            )}

            {["technician_assigned", "technician_in_transit", "technician_at_location", "started"].includes(appointmentData.status) && (
              <React.Fragment>
                <Button
                  bsStyle="danger"
                  fill
                  disabled={loading}
                  onClick={() => {
                    setCompleteModalOpen(true)
                  }}>
                  <Svg name="complete" className="w-4 h-4" />
                  Complete
                </Button>

                <CompleteModal
                  show={completeModalOpen}
                  appointmentId={appointmentData.id}
                  onHide={() => {
                    setCompleteModalOpen(false)
                  }}
                  onConfirm={(app) => {
                    setAppointmentData(app)
                    getPayments(app.id)
                  }}
                />
              </React.Fragment>
            )}

            <Button
              bsStyle="danger"
              fill
              disabled={loading}
              onClick={() => {
                setNotesModalOpen(true)
              }}>
              <Svg name="file-text" className="w-4 h-4" />
              Notes
            </Button>

            <NotesModal
              show={notesModalOpen}
              appointmentId={appointmentData.id}
              notes={appointmentData.adminNotes}
              onHide={() => {
                setNotesModalOpen(false)
              }}
              onConfirm={(notes) => {
                let data = appointmentData
                data.adminNotes = notes
                setAppointmentData(data)
              }}
            />
          </div>
        )}
      </div>

      {loading && (
        <div className="flex flex-col items-center justify-center">
          <Loader title="Loading Appointment Details..." />
        </div>
      )}

      {appointmentError && (
        <div className="error-alert" style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}>
          {appointmentError}
        </div>
      )}

      {appointmentData && (
        <div className="flex flex-col gap-16">
          <Card
            title="Appointment Summary"
            content={
              <div className="grid grid-2col gap-32">
                <div className="flex flex-col gap-4 items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    CODE
                  </div>
                  <div className="sub-text text-semibold text-xs">{appointmentData.id}</div>
                </div>

                <div className="flex flex-col gap-6 items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    STATUS
                  </div>
                  <div className="flex items-center gap-6">
                    {formatStatus(appointmentData.status)}
                    {appointmentData.status === "cancelled" && (
                      <React.Fragment>
                        <span>{"•"}</span>
                        <span>{formatDate(appointmentData.cancelledAt)}</span>
                      </React.Fragment>
                    )}
                  </div>
                  {appointmentData.cancelReason !== "none" && (
                    <React.Fragment>
                      <div className="sub-text text-xs">{formatCancelReason(appointmentData.cancelReason)}</div>
                      {appointmentData.userCancellationReason && (
                        <div className="sub-text text-xs">Reason: {appointmentData.userCancellationReason || "--"}</div>
                      )}
                    </React.Fragment>
                  )}
                </div>

                <div className="flex flex-col gap-4 items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    SCHEDULED TIME
                  </div>
                  {formatDate(appointmentData.startTime)} ({appointmentData.address.city})
                </div>

                <div className="flex flex-col gap-4 items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    APPOINTMENT TYPE
                  </div>
                  {appointmentData.type === "asap" ? "ASAP" : "Scheduled"}
                </div>

                <div className="flex flex-col gap-4 items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    BOOKED AT
                  </div>
                  {formatDate(appointmentData.bookedAt)} ({appointmentData.address.city})
                </div>

                <div className="flex flex-col gap-4 items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    PATIENT NOTES
                  </div>
                  {appointmentData.patientNotes ? appointmentData.patientNotes : "--"}
                </div>

                <div className="flex flex-col gap-4">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    BOOKED ON BEHALF OF
                  </div>
                  <div className="text-semibold text-lg">
                    <Link className="text-semibold" to={`/admin/users/${appointmentData.patient.id}`} title="View Patient Details">
                      {appointmentData.patient.firstName} {appointmentData.patient.lastName}
                    </Link>
                    {appointmentData.corporateId ? (
                      <React.Fragment>
                        {" • "}
                        <div className="badge success">Corporate Profile</div>
                      </React.Fragment>
                    ) : null}
                  </div>
                  <div className="sub-text text-xs">{appointmentData.patient.email}</div>
                  <div className="sub-text text-xs">{appointmentData.patient.phone}</div>
                </div>

                <div className="flex flex-col gap-4 items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    ASSIGNED TECHNICIAN
                  </div>
                  {appointmentData.technician && (
                    <React.Fragment>
                      <div className="text-semibold text-lg">
                        <Link className="text-semibold" to={`/admin/lab-techs-details/${appointmentData.technician.id}`} title="View Technician Details">
                          {appointmentData.technician.firstName} {appointmentData.technician.lastName}
                        </Link>
                      </div>
                      <div className="sub-text text-xs">{appointmentData.technician.email}</div>
                      <div className="sub-text text-xs">{appointmentData.technician.phone}</div>
                    </React.Fragment>
                  )}
                  {!appointmentData.technician && <div className="sub-text text-xs">Not assigned</div>}
                </div>

                <div className="flex flex-col gap-4 items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    TECHNICIAN NOTES
                  </div>
                  {appointmentData.technicianNotes ? appointmentData.technicianNotes : "--"}
                </div>

                <div className="flex flex-col gap-4 items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "6px" }}>
                    ADMIN NOTES
                  </div>
                  {appointmentData.adminNotes ? appointmentData.adminNotes : "--"}
                </div>
              </div>
            }
          />

          <Card title="Timeline" content={<AppointmentTimeline appointment={appointmentData} />} />

          <Card
            title="Cost & Payments"
            content={
              <div className="grid grid-2col gap-32">
                <div className="flex flex-col items-start">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "12px" }}>
                    COST BREAKDOWN
                  </div>

                  <div className="flex flex-col w-full gap-10">
                    <div className="flex items-center">
                      <span>Subtotal</span>
                      <span className="text-semibold ml-auto">
                        $
                        {new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          signDisplay: "never",
                        }).format(appointmentData.cost.subtotal > 0 ? appointmentData.cost.subtotal / 100 : 0)}
                      </span>
                    </div>

                    <div className="flex items-center">
                      <span>Discount {appointmentData.discountCodeUsed ? `(${appointmentData.discountCodeUsed})` : ""}</span>
                      <span className="text-semibold ml-auto">
                        $
                        {new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          signDisplay: "auto",
                        }).format(appointmentData.cost.discount != 0 ? appointmentData.cost.discount / 100 : 0)}
                      </span>
                    </div>

                    <div className="separator horizontal"></div>

                    <div className="flex items-center">
                      <span className="text-semibold">Total ({appointmentData.cost.currency})</span>
                      <span className="text-semibold ml-auto">
                        $
                        {new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          signDisplay: "never",
                        }).format(appointmentData.cost.total > 0 ? appointmentData.cost.total / 100 : 0)}
                      </span>
                    </div>

                    <div className="separator horizontal"></div>

                    {appointmentData.cost.technicianTax > 0 && (
                      <React.Fragment>
                        <div className="flex items-center">
                          <span>Services Commission</span>
                          <span className="ml-auto">
                            $
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              signDisplay: "never",
                            }).format((appointmentData.cost.technicianPortion - appointmentData.cost.technicianTax) / 100)}
                          </span>
                        </div>

                        <div className="flex items-center">
                          <span>
                            Registered Business Tax (
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 3,
                              signDisplay: "never",
                            }).format(appointmentData.cost.technicianTaxPercentage)}
                            %)
                          </span>
                          <span className="ml-auto">
                            $
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              signDisplay: "never",
                            }).format(appointmentData.cost.technicianTax / 100)}
                          </span>
                        </div>
                      </React.Fragment>
                    )}

                    <div className="flex items-center">
                      <span className="text-semibold">Technician's Commission</span>
                      <span className="ml-auto text-semibold">
                        $
                        {new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          signDisplay: "never",
                        }).format(appointmentData.cost.technicianPortion / 100)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-6 items-start w-full">
                  <div className="text-xxs text-semibold main-text" style={{ marginBottom: "10px" }}>
                    PAYMENTS
                  </div>

                  {paymentsList.map((payment, key) => (
                    <div
                      key={key}
                      style={{
                        padding: "8px 12px",
                        border: "1px solid var(--border-color)",
                        borderRadius: "6px",
                      }}
                      className="flex flex-col gap-12 w-full">
                      <div className="flex items-center gap-12">
                        <div className="text-bold text-normal" style={{ whiteSpace: "nowrap" }}>
                          $
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            signDisplay: "never",
                          }).format(payment.amount > 0 ? payment.amount / 100 : 0)}
                        </div>

                        <div className="separator vertical"></div>

                        <div className="flex flex-col gap-4">
                          <div className="sub-text text-xs">
                            <span className="text-semibold main-text">{formatPaymentType(payment.paymentType)}</span>
                            {" • "}
                            {formatPaymentStatus(payment.status)}
                          </div>
                          <div className="sub-text text-xs">
                            {moment(getEpochDate(payment.capturedOn || payment.authorizedOn)).format(dateTimeFormat)}
                            {payment.brand && (
                              <React.Fragment>
                                {" • "}
                                {`${payment.brand.toUpperCase()} **** ${payment.last4}`}
                              </React.Fragment>
                            )}
                          </div>
                          {payment.refundedAmount > 0 && (
                            <div className="flex items-center">
                              <div className="success-text text-xs">
                                Refunded: $
                                {new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  signDisplay: "never",
                                }).format(payment.refundedAmount / 100)}{" "}
                                on {moment(getEpochDate(payment.refundedOn)).format(dateTimeFormat)}
                              </div>
                            </div>
                          )}
                          {payment.error && <div className="theme-text">{payment.error}</div>}
                          <a
                            target="_blank"
                            className="flex items-center gap-4"
                            title="View Payment in Stripe"
                            href={getStripePaymentLink(payment.stripePaymentId)}>
                            View Details in Stripe
                            <Svg className="w-4 h-4" name="external" />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                  {paymentsList.length === 0 && <div className="sub-text text-lg">No charges</div>}
                </div>
              </div>
            }
          />

          {corporateProfile && (
            <Card
              title="Corporate Profile"
              content={
                <div className="flex items-center gap-16">
                  <img
                    style={{
                      width: "64px",
                      height: "64px",
                      overflow: "hidden",
                      borderRadius: "6px",
                      objectFit: "cover",
                      border: "1px solid var(--border-color)",
                    }}
                    src={corporateProfile.picture && corporateProfile.picture.url ? corporateProfile.picture.url : PlaceholderImage}
                    alt="Corporate Profile Picture"
                  />
                  <div className="flex flex-col items-start">
                    <span className="text-bold">{corporateProfile.name}</span>
                    <span className="sub-text">{corporateProfile.email}</span>
                  </div>

                  <Button
                    bsStyle="danger"
                    outline="true"
                    title="View Profile"
                    className="ml-auto"
                    style={{ padding: "7px 10px" }}
                    onClick={() => props.history.push(`/admin/corporate/${corporateProfile.id}`)}>
                    View Profile
                  </Button>
                </div>
              }
            />
          )}

          <Card
            title={
              <div className="flex items-center gap-12">
                Patients
                {(appointmentData.status === "completed" || appointmentData.status === "ended") && (
                  <div style={{ marginLeft: "auto" }}>
                    <Button
                      bsStyle="danger"
                      fill
                      disabled={loading}
                      onClick={() => {
                        setReportsModalOpen(true)
                      }}>
                      <Svg name="send" className="w-4 h-4" />
                      Send Service Results
                    </Button>

                    <ServiceReportsModal
                      show={reportsModalOpen}
                      appointment={appointmentData}
                      onHide={() => {
                        setReportsModalOpen(false)
                      }}
                      onConfirm={(newData) => {
                        setAppointmentData(Object.assign({}, appointmentData, newData))
                        setReportsModalOpen(false)
                      }}
                    />
                  </div>
                )}
              </div>
            }
            content={
              <div className="flex flex-col gap-16">
                {appointmentData.appointmentPatients.map((person, key) => (
                  <div
                    key={key}
                    style={{
                      padding: "8px 12px",
                      border: "1px solid var(--border-color)",
                      borderRadius: "6px",
                    }}
                    className="flex flex-col gap-12">
                    <div className="flex items-start">
                      <div className="flex flex-col gap-4 w-full">
                        <div className="text-semibold text-lg">
                          {person.firstName} {person.lastName}
                        </div>
                        <div className="sub-text text-xs">
                          {person.email}
                          {" • "}
                          {person.phone}
                        </div>
                        <div className="sub-text text-xs">
                          Date of birth: {getNormalDate(person.dateOfBirth).format(dateFormat)}
                          {/*  {" • "}
                          {genders.find((g) => g.key == person.gender.key)
                            ? genders.find((g) => g.key == person.gender.key).value
                          : ""}*/}
                        </div>
                        <div className="separator horizontal" style={{ margin: "6px 0" }}></div>
                        {/* <div className="sub-text text-xs flex items-center">
                          Health Card: {person.healthCareCard.cardNumber},{" "}
                          {person.healthCareCard.province}
                          {person.healthCareCard.expiryDate && (
                            <React.Fragment>
                              , valid until {person.healthCareCard.expiryDate.day}/
                              {person.healthCareCard.expiryDate.month}/
                              {person.healthCareCard.expiryDate.year}
                            </React.Fragment>
                          )}
                          {person.healthCareCard.picture.fileName && (
                            <Button
                              simple
                              style={{
                                fontSize: "12px",
                                padding: "0px 6px",
                                height: "16px",
                                marginLeft: "12px",
                              }}
                              onClick={() => {
                                setHealthCardKey(person.healthCareCard.picture.fileName);
                              }}
                            >
                              <Svg name="eye" style={{ marginRight: "-2px" }} className="w-4 h-4" />
                              View Card
                            </Button>
                          )}
                        </div> */}
                        {false && (
                          <div className="sub-text text-xs">
                            Insurance: {person.insurance.policyNumber || "--"}, {person.insurance.provider || "--"}
                          </div>
                        )}
                        <div className="sub-text text-xs">Issues/Allergies: {person.issuesAllergies || "--"}</div>
                      </div>
                    </div>

                    <div className="separator horizontal"></div>

                    <div className="flex flex-col gap-6">
                      <div className="sub-text text-bold text-xxs">SERVICES</div>
                      <div className="grid grid-2col gap-12">
                        {person.appointmentItems.map((item) => (
                          <div key={item.key} className="flex flex-col items-start appointment-service-card">
                            <div className="text-semibold flex items-center gap-6">
                              <header className="text-semibold">{item.service.title}</header>/
                              <span className="theme-text text-semibold">
                                $
                                {new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  signDisplay: "never",
                                }).format(item.service.price > 0 ? item.service.price / 100 : 0)}
                                {item.service.dynamicPrice ? " (Price Schedule)" : ""}
                              </span>
                              {(appointmentData.status === "completed" || appointmentData.status === "ended") &&
                                appointmentItems &&
                                getAppointmentItemStatus(appointmentItems, item)}
                            </div>

                            {appointmentItems.find((itm) => itm.key === item.key) && appointmentItems.find((itm) => itm.key === item.key).dropOffLocation && (
                              <div className="text-regular text-xs sub-text">{`Dropped off at ${
                                appointmentItems.find((itm) => itm.key === item.key).dropOffLocation.name
                              } on ${moment(getEpochDate(appointmentItems.find((itm) => itm.key === item.key).droppedOffOn)).format(dateTimeFormat)}`}</div>
                            )}

                            <div className="separator horizontal w-full" style={{ margin: "6px 0px" }}></div>

                            <div className="flex items-center flex-wrap" style={{ rowGap: "10px", columnGap: "16px" }}>
                              {item.service.insuranceForm === true && (
                                <Button
                                  simple
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    height: "16px",
                                    gap: "4px",
                                  }}
                                  onClick={() => {
                                    onFormView(item.key, "insurance")
                                  }}>
                                  <Svg name="eye" className="w-4 h-4" />
                                  Insurance Form
                                </Button>
                              )}

                              {item.service.requisitionForm === true && (
                                <Button
                                  simple
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    height: "16px",
                                    gap: "4px",
                                  }}
                                  onClick={() => {
                                    onFormView(item.key, "requisition")
                                  }}>
                                  <Svg name="eye" className="w-4 h-4" />
                                  Requisition Form
                                </Button>
                              )}

                              {item.service.reportId !== null && (
                                <Button
                                  simple
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    height: "16px",
                                    gap: "4px",
                                  }}
                                  onClick={() => {
                                    setReportKey(item.service.reportId)
                                  }}>
                                  <Svg name="file-text" style={{ marginRight: "-2px" }} className="w-4 h-4" />
                                  Report
                                </Button>
                              )}

                              {item.service && item.service.serviceNotes && (
                                <Button
                                  simple
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    height: "16px",
                                    gap: "4px",
                                  }}
                                  onClick={() => {
                                    setMedicalNotes(item.service.serviceNotes)
                                  }}>
                                  <Svg name="comment" className="w-4 h-4" />
                                  Notes
                                </Button>
                              )}

                              {item.service.questionnaire && item.service.questionnaire.length > 0 && (
                                <Button
                                  simple
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    height: "16px",
                                    gap: "4px",
                                  }}
                                  onClick={() => {
                                    setQuestionnaireKey(item.key)
                                  }}>
                                  <Svg name="list" className="w-4 h-4" />
                                  Questionnaire
                                </Button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
          />

          <Card
            title="Address"
            content={
              <div className="flex flex-col gap-12">
                <div className="main-text text-xl text-semibold">{formatFullAddress(appointmentData.address)}</div>
                <div className="separator horizontal"></div>
                <div
                  ref={contentRef}
                  style={{
                    position: "relative",
                    borderRadius: "6px",
                    overflow: "hidden",
                    border: "1px solid var(--border-color)",
                  }}>
                  <MapGL
                    scrollZoom={true}
                    maxPitch={85}
                    {...viewport}
                    onViewportChange={(viewport) => setViewport(viewport)}
                    mapStyle="mapbox://styles/mapbox/light-v10"
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}>
                    <NavigationControl
                      style={{
                        right: 12,
                        top: 12,
                      }}
                    />

                    <Marker key={appointmentData.id} longitude={appointmentData.location.longitude} latitude={appointmentData.location.latitude}>
                      <button
                        className="marker-btn"
                        onClick={(e) => {
                          e.preventDefault()
                        }}>
                        <img src={LocationMarker} alt="Appointment Marker" />
                      </button>
                    </Marker>
                  </MapGL>
                </div>
              </div>
            }
          />
        </div>
      )}

      <FormPreviewModal show={formKey != null} type={formType} formKey={formKey} onHide={() => setFormKey(null)} />

      {appointmentData && (
        <>
          <QuestionnaireModal
            show={questionnaireKey !== undefined}
            appointmentCode={appointmentData.id}
            itemKey={questionnaireKey}
            onHide={() => {
              setQuestionnaireKey(undefined)
            }}
          />

          <ServiceReportPreviewModal show={reportKey != null} reportKey={reportKey} onHide={() => setReportKey(null)} />

          <HealthCardPreviewModal show={healthCardKey != null} formKey={healthCardKey} onHide={() => setHealthCardKey(null)} />

          <MedicalNotesModal
            notes={medicalNotes}
            show={medicalNotes !== null && medicalNotes !== undefined}
            onHide={() => {
              setMedicalNotes(undefined)
            }}
          />
        </>
      )}
    </div>
  )
}

export default AppointmentDetails
